import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { EGuidelineType } from '../../models/enums/guideline-type.enum'
import { IGuideline } from '../../models/interfaces/guidelines.interface'
import { ICreateGuidelineResponse, IGetGuidelineResponse } from '../../models/interfaces/responses/guidelines-responses'
import { createGuidelineService, getGuidelineService } from '../../services/guideline.service'

interface ClientGuidelinesState {
  guideline: IGuideline | null
  loading: boolean
  error: { status: number; message: string } | null
}

const initialState: ClientGuidelinesState = {
  guideline: null,
  loading: false,
  error: null,
}

export const fetchGuidelineThunk = createAsyncThunk<
  IGetGuidelineResponse,
  { referenceType: EGuidelineType; referenceId: string },
  { rejectValue: { status: number; message: string } }
>('guidelines/fetchGuideline', async ({ referenceType, referenceId }) => {
  return await getGuidelineService(referenceType, referenceId)
})

export const createOrUpdateGuidelineThunk = createAsyncThunk<
  ICreateGuidelineResponse,
  Partial<IGuideline>,
  { rejectValue: { status: number; message: string } }
>('guidelines/createGuideline', async (guidelineData) => {
  return await createGuidelineService(guidelineData)
})

const handleError = (state: ClientGuidelinesState, action: PayloadAction<any>) => {
  state.loading = false
  state.error = action.payload || { status: 500, message: 'An unknown error occurred' }
}

const guidelinesSlice = createSlice({
  name: 'guidelines',
  initialState,
  reducers: {
    updateGuideline(state, action: PayloadAction<Partial<IGuideline>>) {
      if (state.guideline) {
        state.guideline = { ...state.guideline, ...action.payload }
      } else {
        state.guideline = { ...action.payload } as IGuideline
      }
    },
    updateColors(state, action: PayloadAction<string[]>) {
      if (state.guideline) {
        state.guideline.colors = action.payload
      } else {
        state.guideline = { colors: action.payload } as IGuideline
      }
    },
    resetGuideline(state) {
      state.guideline = null
      state.loading = false
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGuidelineThunk.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchGuidelineThunk.fulfilled, (state, action: PayloadAction<IGetGuidelineResponse>) => {
        state.guideline = action.payload.data
        state.loading = false
      })
      .addCase(fetchGuidelineThunk.rejected, handleError)

      .addCase(createOrUpdateGuidelineThunk.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createOrUpdateGuidelineThunk.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(createOrUpdateGuidelineThunk.rejected, handleError)
  },
})

export const { updateColors, updateGuideline, resetGuideline } = guidelinesSlice.actions
export default guidelinesSlice.reducer
