import { apiEndpoints } from '../constants/api-endpoints'
import { ICreateOrUpdateDiscoveryPayload } from '../models/interfaces/requests/discovery-payload.interface'
import {
  ICreateOrUpdateDiscoveryResponse,
  IGetDiscoveryResponse,
  IUpdateDiscoveryTextResponse,
} from '../models/interfaces/responses/discovery-responses'
import { apiRequest } from '../utils/api-utils/api'

const createOrUpdateDiscoveryService = async (
  discoveryData: ICreateOrUpdateDiscoveryPayload,
): Promise<ICreateOrUpdateDiscoveryResponse> => {
  try {
    return await apiRequest('POST', apiEndpoints.DISCOVERY.CREATE_OR_UPDATE_DISCOVERY, discoveryData)
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error creating or updating discovery')
  }
}

const getDiscoveryService = async (messagingId: string): Promise<IGetDiscoveryResponse> => {
  return await apiRequest('GET', apiEndpoints.DISCOVERY.GET_DISCOVERIES(messagingId))
}

const updateDiscoveryTextService = async (
  discoveryId: string,
  discoveryText: string,
): Promise<IUpdateDiscoveryTextResponse> => {
  return await apiRequest('PATCH', apiEndpoints.DISCOVERY.UPDATE_DISCOVERY_TEXT, {
    discoveryId,
    discoveryText,
  })
}

export { createOrUpdateDiscoveryService, getDiscoveryService, updateDiscoveryTextService }
