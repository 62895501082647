import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './ModalDropdown.module.scss'

interface IModalDropdownProps {
  children: React.ReactNode
  size?: 'small' | 'medium' | 'large'
}
const ModalDropdown: FC<IModalDropdownProps> = ({ children, size = 'medium' }) => {
  return (
    <div className={classNames(styles.modalDropdown, styles[size])}>
      <div className={classNames(styles.content, styles[size])}>{children}</div>
    </div>
  )
}

export { ModalDropdown }
