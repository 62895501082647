export enum EDiscoveryDataField {
  GENERAL_INFORMATION = 'generalInformation',
  CLIENT_REFERENCES = 'clientReferences',
  TARGET_AUDIENCE = 'targetAudience',
  DISCOVERY_SUMMARY = 'discoverySummary',
  CUSTOMER_CASE_STUDIES = 'customerCaseStudies',
  PROOF = 'proof',
  MORE_INFORMATION = 'moreInformation',
  MORE_FILES = 'moreFiles',
}
