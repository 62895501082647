import { Dropdown, MenuProps, Spin, message } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import { DownArrowIcon, ExportIcon, MenuIcon, TrashRedIcon } from '../../../assets/icons'
import { customIcon } from '../../../models/consts/spinner'
import { strings } from '../../../models/consts/strings'
import { EFileSubReferenceType } from '../../../models/enums/file-reference-type.enum'
import { EStatusEnum } from '../../../models/enums/status.enum'
import { EWizardTabs } from '../../../models/enums/wizard-tabs.enum'
import { IFile } from '../../../models/interfaces/file.interface'
import { IGuideline } from '../../../models/interfaces/guidelines.interface'
import { IProject } from '../../../models/interfaces/project.interface'
import { IUser } from '../../../models/interfaces/users.interface'
import { exportWordService } from '../../../services/documents-service'
import { deleteProjectThunk } from '../../../store/slices/business-unit.slice'
import { updateProjectThunk } from '../../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import BackButton from '../../BackButton/BackButton'
import ConfirmModal from '../../ConfirmModal/ConfirmModal'
import { GenericButton } from '../../GenericButton/GenericButton'
import { StatusBox } from '../../StatusBox/StatusBox'
import { EditableNameField } from './EditableNameField/EditableNameField'
import { BrandModal } from './WizardGenericModal/BrandModal/BrandModal'
import { DetailsModal } from './WizardGenericModal/DetailsModal/DetailsModal'
import { MembersModal } from './WizardGenericModal/MembersModal/MembersModal'
import { ToneOfVoiceModal } from './WizardGenericModal/ToneOfVoiceModal/ToneOfVoiceModal'
import styles from './WizardHeader.module.scss'
import { WizardTab } from './WizardTab/WizardTab'
import { handleSetGuideline, handleSetGuidelineFiles, tabs } from './wizard-header.utils'

interface IWizardHeaderProps {
  project?: IProject
  onLoading?: (isLoading: boolean) => void
  saveChanges: () => void
}

const WizardHeader: FC<IWizardHeaderProps> = ({ project, onLoading, saveChanges }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [businessUnitGuidelines, setBusinessUnitGuidelines] = useState<IGuideline | null>(null)
  const [guidelineFiles, setGuidelineFiles] = useState<IFile[]>([])
  const [logoFiles, setLogoFiles] = useState<IFile[]>([])
  const [fontFile, setFontFile] = useState<IFile | undefined>(undefined)

  const [members, setMembers] = useState<IUser[]>(project?.users || [])
  const [membersCount, setMembersCount] = useState<number>(project?.users ? project.users.length : 1)

  const [isGuidelineLoading, setIsGuidelineLoading] = useState(false)
  const [isGuidelineFilesLoading, setIsGuidelineFilesLoading] = useState(false)
  const [isMembersLoading, setIsMembersLoading] = useState(false)

  const isLoading = isGuidelineLoading || isGuidelineFilesLoading || isMembersLoading
  const computedIsLoading = !project || isLoading

  const [activeModal, setActiveModal] = useState<EWizardTabs | null>(null)
  const [isStatusHovered, setIsStatusHovered] = useState(false)
  const [isStatusDropDownOpen, setIsStatusDropDownOpen] = useState(false)
  const [deleteProjectModal, setDeleteProjectModal] = useState(false)
  const [isNameChangeLoading, setIsNameChangeLoading] = useState(false)
  const [isDeleteProjectLoading, setIsDeleteProjectLoading] = useState(false)
  const [localStatus, setLocalStatus] = useState<EStatusEnum | undefined>(project?.status)

  const discoveryText = useAppSelector((state) => state.wizard.data?.discovery?.discoveryText)
  const competitiveAnalysisText = useAppSelector((state) => state.wizard.data?.competitiveAnalysis?.competitorsText)
  const messagingEngineText = useAppSelector((state) => state.wizard.data?.messagingEngine?.messagingEngineText)

  useEffect(() => {
    if (onLoading) {
      onLoading(computedIsLoading)
    }
  }, [computedIsLoading, onLoading])

  useEffect(() => {
    if (!project?.businessUnit.id) return
    ;(async () => {
      setIsGuidelineLoading(true)
      await handleSetGuideline(setBusinessUnitGuidelines, project?.businessUnit.id)
      setIsGuidelineLoading(false)
    })()
  }, [project?.businessUnit.id])

  useEffect(() => {
    if (!businessUnitGuidelines?.id) return
    ;(async () => {
      setIsGuidelineFilesLoading(true)
      await handleSetGuidelineFiles(setGuidelineFiles, businessUnitGuidelines.id!)
      setIsGuidelineFilesLoading(false)
    })()
  }, [businessUnitGuidelines?.id])

  useEffect(() => {
    if (!guidelineFiles) return

    const logos = guidelineFiles.filter((file: IFile) => file.subReferenceType === EFileSubReferenceType.LOGO)
    setLogoFiles(logos)

    const font = guidelineFiles.find((file: IFile) => file.subReferenceType === EFileSubReferenceType.FONT)
    setFontFile(font)
  }, [guidelineFiles])

  useEffect(() => {
    if (project?.users) {
      setIsMembersLoading(true)
      setMembers(project.users)
      setMembersCount(project?.users?.length)
      setLocalStatus(project.status)
      setIsMembersLoading(false)
    }
  }, [project?.users, project?.status])

  const handleBackClick = async () => {
    try {
      await saveChanges()
      navigate(-1)
    } catch (error) {
      console.error('Error saving before navigating:', error)
      message.error('Failed to save changes before navigating.')
    }
  }

  const generateStatusItems = (): MenuProps['items'] =>
    Object.values(EStatusEnum)
      .slice(0, 4)
      .map((status) => ({
        key: status,
        label: <StatusBox status={status} />,
      }))

  const handleUpdateName = async (newName: string) => {
    if (project) {
      setIsNameChangeLoading(true)
      try {
        await dispatch(
          updateProjectThunk({
            projectId: project?.id,
            payload: { name: newName },
          }),
        )
      } catch {
        message.error('Failed to change project name, please try again')
      } finally {
        setIsNameChangeLoading(false)
      }
    }
  }

  const handleStatusChange = async (selectedKey: string) => {
    if (!project) return
    const oldStatus = localStatus
    const newStatus = selectedKey as EStatusEnum
    setLocalStatus(newStatus)

    try {
      await dispatch(
        updateProjectThunk({
          projectId: project.id,
          payload: { status: newStatus },
        }),
      ).unwrap()
    } catch {
      setLocalStatus(oldStatus)
      message.error('Failed to change status, please try again')
    }
  }

  const handleDeleteProjectModalOk = async () => {
    if (project) {
      setIsDeleteProjectLoading(true)
      try {
        await dispatch(deleteProjectThunk({ projectId: project.id })).unwrap()
        message.success('Project deleted successfully')
        navigate(-1)
        setDeleteProjectModal(false)
      } catch (error) {
        console.error('Error deleting project:', error)
        message.error('Error: Something went wrong while deleting the project')
      } finally {
        setIsDeleteProjectLoading(false)
      }
    }
  }

  const handleDropdownClick = ({ key }: { key: string }) => {
    if (key === 'delete') {
      setDeleteProjectModal(true)
    }
  }

  const handleExportClick = async () => {
    const requiredSections: { name: string; condition: boolean }[] = [
      { name: 'Discovery', condition: !discoveryText },
      { name: 'Competitive Analysis', condition: !competitiveAnalysisText },
      { name: 'Messaging', condition: !messagingEngineText },
      {
        name: 'Color Palette',
        condition: !businessUnitGuidelines?.colors || businessUnitGuidelines?.colors?.length < 1,
      },
    ]

    const missingSteps = requiredSections.filter((section) => section.condition).map((section) => section.name)

    if (missingSteps.length) {
      message.warning(`Please complete the following sections before exporting: ${missingSteps.join(', ')}`)
      return
    }

    saveChanges()

    if (!project) {
      console.error('Project is not defined')
      message.warning('Some data is missing, please try again')
    } else if (!businessUnitGuidelines?.colors) {
      console.error('Business Unit Guidelines are not defined', businessUnitGuidelines, businessUnitGuidelines?.colors)
      message.warning('Some data is missing, please try again')
    } else {
      exportWordService(
        project?.messaging.id,
        project.name,
        businessUnitGuidelines,
        logoFiles,
        fontFile,
        project?.businessUnit.client.name,
      )
    }
  }

  const dropDownItems: MenuProps['items'] = [
    {
      key: 'delete',
      label: (
        <div className={styles.dropdownItem}>
          <TrashRedIcon className={styles.dropdownIcon} />
          {strings.WIZARD.HEADER.DROPDOWN.DELETE_PROJECT}
        </div>
      ),
    },
  ]

  return (
    <div className={styles.wizardHeader}>
      <BackButton onClick={handleBackClick} title={strings.WIZARD.HEADER.BACK_BUTTON} />
      {/*Client, BU, Project Names Section */}
      <div className={styles.headerContainer}>
        <div className={styles.title}>
          <div className={styles.names}>
            <span className={styles.businessUnitTab}>{project?.businessUnit.client.name}</span>
            <span className={styles.tab}>/</span>
            <span className={styles.clientTab}>{project?.businessUnit.name}</span>

            <span className={styles.tab}>/</span>
            <Spin className={styles.spinner} spinning={isNameChangeLoading} indicator={customIcon}>
              <EditableNameField fieldName={project?.name} onUpdateName={(newName) => handleUpdateName(newName)} />
            </Spin>
          </div>
          <Dropdown
            className={styles.statusDropDown}
            menu={{
              items: generateStatusItems(),
              onClick: ({ key }) => handleStatusChange(key),
            }}
            trigger={['click']}
            placement="bottom"
            open={isStatusDropDownOpen}
            onOpenChange={(open) => setIsStatusDropDownOpen(open)}
          >
            <div
              className={styles.statusContainer}
              onMouseEnter={() => setIsStatusHovered(true)}
              onMouseLeave={() => setIsStatusHovered(false)}
            >
              <StatusBox className={styles.statusBox} status={localStatus} />
              {(isStatusHovered || isStatusDropDownOpen) && (
                <span className={styles.dropdownIcon}>
                  <DownArrowIcon
                    className={classNames(styles.downArrowIcon, { [styles.open]: isStatusDropDownOpen })}
                  />
                </span>
              )}
            </div>
          </Dropdown>
        </div>
        {/*Right Tabs Section */}
        <div className={styles.tabs}>
          {tabs.map(({ id, title, Icon, modal }) => (
            <WizardTab
              onClick={() => setActiveModal((prev) => (prev === modal ? null : modal))}
              key={id}
              Icon={Icon}
              title={title}
            />
          ))}
          <div className={styles.membersMenuContainer}>
            <div className={styles.membersTab}>
              <GenericButton
                onClick={() => setActiveModal((prev) => (prev === EWizardTabs.MEMBERS ? null : EWizardTabs.MEMBERS))}
                secondary
                title={`${strings.WIZARD.HEADER.TABS.MEMBERS} / ${membersCount}`}
              />
            </div>
            <button className={styles.dropdownItem} onClick={handleExportClick}>
              <ExportIcon className={styles.dropdownIcon} />
              {strings.WIZARD.HEADER.DROPDOWN.EXPORT}
            </button>
            {/*MenuIcon Section */}
            <Dropdown
              className={styles.menuDropDown}
              menu={{ items: dropDownItems, onClick: handleDropdownClick }}
              trigger={['click']}
              placement="bottomRight"
            >
              <MenuIcon className={styles.menuIcon} />
            </Dropdown>
          </div>
        </div>
        <div className={styles.modalsContainer}>
          {/*Details Modal Section */}
          {activeModal === EWizardTabs.DETAILS && (
            <DetailsModal
              website={businessUnitGuidelines?.website ?? ''}
              parentWebsite={businessUnitGuidelines?.parentWebsite ?? ''}
              generalInformation={businessUnitGuidelines?.generalInformation}
              onClose={() => setActiveModal(null)}
            />
          )}
          {/*Brand Modal Section */}
          {activeModal === EWizardTabs.BRAND && (
            <BrandModal
              onClose={() => setActiveModal(null)}
              logos={logoFiles.map((file) => file.fileUrl)}
              font={fontFile}
              colors={businessUnitGuidelines?.colors}
            />
          )}
          {/*Tone of Voice Modal Section */}
          {activeModal === EWizardTabs.TONE_OF_VOICE && (
            <ToneOfVoiceModal
              toneOfVoice={project?.toneOfVoice || businessUnitGuidelines?.toneOfVoice}
              onClose={() => setActiveModal(null)}
              projectId={project?.id}
            />
          )}
          {/*Members Modal Section */}
          {activeModal === EWizardTabs.MEMBERS && (
            <MembersModal
              onUpdateMembers={(updatedMembers: IUser[]) => {
                setMembers(updatedMembers)
                setMembersCount(updatedMembers.length)
              }}
              onClose={() => setActiveModal(null)}
              projectId={project?.id}
              projectMembers={members}
            />
          )}
        </div>
        <ConfirmModal
          isOpen={deleteProjectModal}
          onOk={handleDeleteProjectModalOk}
          onCancel={() => setDeleteProjectModal(false)}
          isDangerButton
          primaryTitle={strings.BUSINESS_UNIT_PAGE.DELETE_MODAL.TITLE1}
          secondaryTitle={strings.BUSINESS_UNIT_PAGE.DELETE_MODAL.TITLE2}
          confirmButtonTItle={strings.GENERAL_LABELS.DELETE_NOW}
          isLoading={isDeleteProjectLoading}
        />
      </div>
    </div>
  )
}
export { WizardHeader }
