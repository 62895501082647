import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { PAGINATION_CONFIG } from '../../models/consts/pagination-config'
import { IClient } from '../../models/interfaces/client.interface'
import { ICreateClientsResponse, IGetClientsResponse } from '../../models/interfaces/responses/clients-responses'
import { createClientService, fetchClientsService } from '../../services/clients.service'

interface ClientsState {
  clients: IClient[]
  isLoading: boolean
  error: string | null
  page: number
  limit: number
  itemsCount: number
  pagesCount: number
}

const initialState: ClientsState = {
  clients: [],
  isLoading: true,
  error: null,
  page: PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: PAGINATION_CONFIG.DEFAULT_LIMIT,
  itemsCount: 0,
  pagesCount: 1,
}

export const fetchClientsThunk = createAsyncThunk<
  IGetClientsResponse,
  {
    page?: number
    limit?: number
    sort?: { field: string; order: 'ASC' | 'DESC' }
    filter?: { field: string; value: string }[]
    search?: string
  }
>(
  'clients/fetchClients',
  async ({ page = PAGINATION_CONFIG.DEFAULT_PAGE, limit = PAGINATION_CONFIG.DEFAULT_LIMIT, sort, filter, search }) => {
    return fetchClientsService(page, limit, sort, filter, search)
  },
)

export const createClient = createAsyncThunk<ICreateClientsResponse, { name: string; IndustryName: string }>(
  'projects/createProject',
  async ({ name, IndustryName }) => {
    const response = await createClientService(name, IndustryName)
    return response
  },
)

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    clearClients: (state) => {
      state.clients = []
      state.itemsCount = 0
      state.pagesCount = 0
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientsThunk.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchClientsThunk.fulfilled, (state, action: PayloadAction<IGetClientsResponse>) => {
        state.isLoading = false
        state.clients = action.payload.data.clients
        state.itemsCount = action.payload.data.meta.itemsCount
        state.pagesCount = action.payload.data.meta.pagesCount
      })
      .addCase(fetchClientsThunk.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'An unexpected error occurred while fetching clients'
      })
  },
})

export const { clearClients } = clientsSlice.actions

export default clientsSlice.reducer
