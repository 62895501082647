import { apiEndpoints } from '../constants/api-endpoints'
import { getCompetitiveAnalysisService } from './competitive-analysis.service'
import { getDiscoveryService } from './discovery.service'
import { getMessagingEngineService } from './messaging-engine.service'
import { IGuideline } from '../models/interfaces/guidelines.interface'
import { IFile } from '../models/interfaces/file.interface'
import axiosInstance from '../utils/api-utils/axios-interceptor'

export const exportWordService = async (
  messagingId: string,
  projectName: string,
  guideline: IGuideline,
  logos: IFile[],
  fontFile: IFile | undefined,
  clientName: string,
) => {
  try {
    const discoveryText = (await getDiscoveryService(messagingId)).data[0].discoveryText
    const competitiveAnalysis = (await getCompetitiveAnalysisService(messagingId)).data[0]
    const { competitorsText: competitorsTexts, competitors } = competitiveAnalysis
    const messagingEngineText = (await getMessagingEngineService(messagingId)).data[0].messagingEngineText
    const text = {
      discoveryText,
      competitorsTexts,
      messagingEngineText,
    }
    const gradientColors = guideline.colors
    const font = { fileName: fontFile?.fileName, fileUrl: fontFile?.fileUrl }
    const minifiedLogos = logos.map((logo) => ({
      fileUrl: logo.fileUrl,
      fileName: logo.fileName,
      fileType: logo.fileType,
    }))

    const response = await axiosInstance.post(
      apiEndpoints.DOCUMENTS.WORD_DOCUMENT,
      { text, gradientColors, projectName, logos: minifiedLogos, font, competitors, clientName },
      { responseType: 'blob' },
    )

    const fileBlob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    })

    const blobUrl = window.URL.createObjectURL(fileBlob)

    const link = document.createElement('a')
    link.href = blobUrl
    link.setAttribute('download', `${projectName || 'document'}.docx`)
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error('Failed to download DOCX file:', error)
  }
}
