import { FC, useEffect, useState } from 'react'

import { strings } from '../../../../models/consts/strings'
import { EWizardType, IWizardType } from '../../../../models/interfaces/wizard-type.interface'
import { GenericButton } from '../../../GenericButton/GenericButton'
import WizardInput from '../../WizardInput/WizardInput'
import styles from './GeneralInformation.module.scss'

interface IGeneralInformationProps {
  onNext: (data: IWizardType[]) => void
  value?: IWizardType[]
  title: string
  placeholder: string
  onCompleteStatusChange?: (completed: boolean) => void
}

const GeneralInformation: FC<IGeneralInformationProps> = ({
  onNext,
  value,
  title,
  placeholder,
  onCompleteStatusChange,
}) => {
  const [inputValue, setInputValue] = useState<string>(value && value.length > 0 ? value[0].value : '')
  const handleInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(value)
  }

  useEffect(() => {
    if (!inputValue.trim()) {
      onCompleteStatusChange?.(false)
    }
  }, [inputValue, onCompleteStatusChange])

  const handleNext = () => {
    const objectValue: IWizardType[] = [{ type: EWizardType.TEXT, value: inputValue }]
    onNext(objectValue)
  }
  return (
    <div className={styles.generalInformationContainer}>
      <p className={styles.title}>{title}</p>
      <WizardInput
        value={inputValue}
        placeholder={placeholder}
        onChange={handleInputChange}
        className={styles.discoveryInput}
      />
      <div className={styles.nextButton}>
        <GenericButton isDisabled={!inputValue.trim()} title={strings.GENERAL_LABELS.NEXT} onClick={handleNext} />
      </div>
    </div>
  )
}
export default GeneralInformation
