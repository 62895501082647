import { ColorPicker, message } from 'antd'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { strings } from '../../../models/consts/strings'
import { RootState } from '../../../store/store'
import { RefreshButton } from '../../RefreshButton/RefreshButton'
import styles from './ColorPalette.module.scss'
import { updateColors } from '../../../store/slices/guideline.slice'

const ColorPalette: FC = () => {
  const minColors = 4

  const initializeColors = () => {
    const guidelineColors = guideline?.colors || []
    const additionalColors = Math.max(minColors - guidelineColors.length, 0)
    return [...guidelineColors, ...Array(additionalColors).fill('Add Color'), 'Add Color']
  }

  const dispatch = useDispatch()
  const { guideline } = useSelector((state: RootState) => state.guidelineSlice)

  const [colors, setColors] = useState<string[]>(initializeColors())

  const handleColorChange = (newColor: string, index: number) => {
    const updatedColors = [...colors]
    updatedColors[index] = newColor

    if (!updatedColors.includes('Add Color')) {
      updatedColors.push('Add Color')
    }

    setColors(updatedColors)
    dispatch(updateColors(updatedColors.filter((color) => color !== 'Add Color')))
  }

  const handleResetColors = () => {
    const defaultColors = Array(minColors).fill('Add Color').concat('Add Color')
    setColors(defaultColors)
    dispatch(updateColors([]))
    message.success('Color palette reset successfully.')
  }

  return (
    <div className={styles.colorPaletteContainer}>
      <div className={styles.topContainer}>
        <h3 className={styles.title}>{strings.GUIDELINE.TITLES.COLOR_PALETTE}</h3>
        <RefreshButton onClick={handleResetColors} />
      </div>
      <div className={styles.paletteContainer}>
        {colors.map((color, index) => (
          <div key={index} className={styles.colorItem}>
            <ColorPicker
              size="large"
              value={color !== 'Add Color' ? color : '#FFFFFF'}
              onChange={(newColor) => handleColorChange(newColor.toHexString(), index)}
            />
            <label className={styles.colorLabel}>{color}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export { ColorPalette }
