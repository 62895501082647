import { Collapse, message } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'

import {
  AddMoreFilesIcon,
  AddMoreInfoIcon,
  ClientReferenceIcon,
  CompletedGreenIcon,
  CustomerCaseStudiesIcon,
  DiscoverySummaryIcon,
  GeneralInformationIcon,
  ProofIcon,
  TargetAudienceIcon,
} from '../../../assets/icons'
import { DOCUMENT_FILE_TYPES } from '../../../models/consts/document-type'
import { strings } from '../../../models/consts/strings'
import { EDiscoveryDataField } from '../../../models/enums/discovery-data-fields.enum'
import { EFileReferenceType, EFileSubReferenceType } from '../../../models/enums/file-reference-type.enum'
import { AccordionItem } from '../../../models/interfaces/accordion-item.interface'
import { IDiscovery } from '../../../models/interfaces/discovery.interface'
import { IFile } from '../../../models/interfaces/file.interface'
import { IGuideline } from '../../../models/interfaces/guidelines.interface'
import { IProject } from '../../../models/interfaces/project.interface'
import { ICreateOrUpdateDiscoveryPayload } from '../../../models/interfaces/requests/discovery-payload.interface'
import { EWizardType, IWizardType } from '../../../models/interfaces/wizard-type.interface'
import { getFilesService } from '../../../services/files-upload.service'
import {
  createOrUpdateDiscovery,
  getDiscoveryThunk,
  resetDiscovery,
  updateDiscovery,
} from '../../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { isDateAfter } from '../../../utils/date.util'
import { handleSetGuideline } from '../WizardHeader/wizard-header.utils'
import WizardStepGenericComponent from '../WizardStepGenericComponent/WizardStepGenericComponent'
import GeneralInformation from './GeneralInformation/GeneralInformation'
import TargetAudience from './TargetAudience/TargetAudience'
import UploadFilesDropDown from './UploadFilesDropDown/UploadFilesDropDown'
import styles from './WizardDiscovery.module.scss'
import { createLanguageGuidelines, removeNameField } from './discovery.utils'

interface IWizardDiscoveryProps {
  messagingId?: string
  project?: IProject
  AILoading: (isLoading: boolean) => void
  onLoading: (isLoading: boolean) => void
}

const WizardDiscovery: FC<IWizardDiscoveryProps> = ({ project, messagingId, AILoading, onLoading }) => {
  const dispatch = useAppDispatch()
  const discovery = useAppSelector((state) => state.wizard.data?.discovery)
  const pollingInterval = useRef<NodeJS.Timeout | null>(null)

  const [businessUnitGuidelines, setBusinessUnitGuidelines] = useState<IGuideline | null>(null)
  const [activeKey, setActiveKey] = useState<string>('')
  const [completedKeys, setCompletedKeys] = useState<string[]>([])
  const [isAddMoreInfo, setIsAddMoreInfo] = useState<boolean>(false)
  const [isAddMoreLinksAndFiles, setIsAddMoreLinksAndFiles] = useState<boolean>(false)
  const [clientReferencesFiles, setClientReferencesFiles] = useState<IWizardType[]>([])
  const [discoverySummaryFiles, setDiscoverySummaryFiles] = useState<IWizardType[]>([])
  const [customerCaseStudiesFiles, setCustomerCaseStudiesFiles] = useState<IWizardType[]>([])
  const [additionalFiles, setAdditionalFiles] = useState<IWizardType[]>([])
  const [proofFiles, setProofFiles] = useState<IWizardType[]>([])

  useEffect(() => {
    ;(async () => {
      if (!messagingId) return
      onLoading(true)
      try {
        const resultAction = await dispatch(getDiscoveryThunk({ messagingId }))
        if (!getDiscoveryThunk.fulfilled.match(resultAction)) {
          console.error('Failed to fetch discovery data')
          return
        }
        const [disc] = resultAction.payload.data

        if (!disc?.id) return

        const files: IFile[] = (await getFilesService(EFileReferenceType.DISCOVERY, disc.id)).data

        const clientReferencesFiles = files.filter(
          (file) => file.subReferenceType === EFileSubReferenceType.CLIENT_REFERENCE,
        )
        const discoverySummaryFiles = files.filter(
          (file) => file.subReferenceType === EFileSubReferenceType.DISCOVERY_SUMMARY,
        )
        const customerCaseStudiesFiles = files.filter(
          (file) => file.subReferenceType === EFileSubReferenceType.CUSTOMER_CASE_STUDIES,
        )

        const proofFiles = files.filter((file) => file.subReferenceType === EFileSubReferenceType.PROOF)

        const additionalFiles = files.filter((file) => file.subReferenceType === EFileSubReferenceType.ADDITIONAL_FILES)

        const updatedClientReferences: IWizardType[] = clientReferencesFiles.map((file) => ({
          type: EWizardType.FILE,
          value: file.fileUrl,
          name: file.fileName,
          id: file.id,
        }))

        const updatedDiscoverySummary: IWizardType[] = discoverySummaryFiles.map((file) => ({
          type: EWizardType.FILE,
          value: file.fileUrl,
          name: file.fileName,
          id: file.id,
        }))

        const updatedCustomerCaseStudies: IWizardType[] = customerCaseStudiesFiles.map((file) => ({
          type: EWizardType.FILE,
          value: file.fileUrl,
          name: file.fileName,
          id: file.id,
        }))

        const updatedProofFiles: IWizardType[] = proofFiles.map((file) => ({
          type: EWizardType.FILE,
          value: file.fileUrl,
          name: file.fileName,
          id: file.id,
        }))

        const updatedAdditionalFiles: IWizardType[] = additionalFiles.map((file) => ({
          type: EWizardType.FILE,
          value: file.fileUrl,
          name: file.fileName,
          id: file.id,
        }))

        setClientReferencesFiles(updatedClientReferences)
        setDiscoverySummaryFiles(updatedDiscoverySummary)
        setCustomerCaseStudiesFiles(updatedCustomerCaseStudies)
        setAdditionalFiles(updatedAdditionalFiles)
        setProofFiles(updatedProofFiles)
      } catch (error) {
        message.error('Error fetching file details.')
        console.error('Error fetching file: ', error)
      } finally {
        onLoading(false)
      }
    })()

    return () => {
      dispatch(resetDiscovery())
    }
  }, [dispatch, messagingId, onLoading])

  useEffect(() => {
    if (!discovery?.discoveryData) return
    const { discoveryData } = discovery

    if (clientReferencesFiles.length && !discoveryData.clientReferences?.length) {
      dispatch(
        updateDiscovery({
          discoveryData: {
            ...discoveryData,
            clientReferences: clientReferencesFiles,
          },
        }),
      )
    }
    if (discoverySummaryFiles.length && !discoveryData.discoverySummary?.length) {
      dispatch(
        updateDiscovery({
          discoveryData: {
            ...discoveryData,
            discoverySummary: discoverySummaryFiles,
          },
        }),
      )
    }
    if (additionalFiles.length && !discoveryData.moreInformation?.length) {
      dispatch(
        updateDiscovery({
          discoveryData: {
            ...discoveryData,
            moreInformation: additionalFiles,
          },
        }),
      )
    }

    const stepsMapping = [
      { key: '1', field: discoveryData.generalInformation },
      { key: '2', field: discoveryData.clientReferences },
      { key: '3', field: discoveryData.targetAudience },
      { key: '4', field: discoveryData.discoverySummary },
      { key: '5', field: discoveryData.customerCaseStudies },
      { key: '6', field: discoveryData.proof },
      { key: '7', field: discoveryData.moreInformation },
      { key: '8', field: discoveryData.moreFiles },
    ]
    const completed = stepsMapping.filter(({ field }) => Array.isArray(field) && field.length > 0).map(({ key }) => key)
    setCompletedKeys(completed)
  }, [discovery, clientReferencesFiles, discoverySummaryFiles, additionalFiles, dispatch])

  useEffect(() => {
    if (!project?.businessUnit.id) return
    ;(async () => {
      await handleSetGuideline(setBusinessUnitGuidelines, project?.businessUnit.id)
    })()
  }, [project?.businessUnit.id])

  useEffect(() => {
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current)
      }
    }
  }, [])

  const GenerateDiscovery = async () => {
    if (messagingId && discovery) {
      const { metadata, discoveryText, createdAt, updatedAt, ...filteredDiscovery } = discovery

      const cleanedDiscoveryData: IDiscovery['discoveryData'] = Object.fromEntries(
        Object.entries(filteredDiscovery.discoveryData).map(([key, values]) => {
          if (Array.isArray(values)) {
            return [key, removeNameField(values)]
          } else {
            return [key, values]
          }
        }),
      )

      const discoveryAdditionalFiles = cleanedDiscoveryData.moreFiles || []
      if (discoveryAdditionalFiles.length > 0) {
        cleanedDiscoveryData.moreInformation = [
          ...(cleanedDiscoveryData.moreInformation || []),
          ...discoveryAdditionalFiles,
        ]
      }

      delete cleanedDiscoveryData.moreFiles

      if (!businessUnitGuidelines) {
        message.error('Guideline is missing to generate discovery')
        return
      }

      const languageGuidelines = createLanguageGuidelines(businessUnitGuidelines)

      const discoveryWithGuideline = {
        ...cleanedDiscoveryData,
        languageGuidelines,
        productName: project?.name || '',
      }

      const cleanedDiscovery: ICreateOrUpdateDiscoveryPayload = {
        id: filteredDiscovery.id,
        messagingId: filteredDiscovery.messagingId,
        discoveryData: discoveryWithGuideline,
      }

      AILoading(true)

      try {
        const resultAction = await dispatch(createOrUpdateDiscovery(cleanedDiscovery))
        if (createOrUpdateDiscovery.rejected.match(resultAction)) {
          throw new Error(resultAction.payload?.message || 'Error creating or updating discovery')
        }
        pollForUpdatedDiscovery(resultAction?.payload?.data?.updatedAt)
      } catch (error) {
        message.error('Something went wrong while generating discovery')
        console.error('Error generating discovery:', error)
        AILoading(false)
      }
    }
  }

  const pollForUpdatedDiscovery = (updatedAt: string | undefined) => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current)
    }

    pollingInterval.current = setInterval(async () => {
      try {
        if (messagingId) {
          const resultAction = await dispatch(getDiscoveryThunk({ messagingId }))
          if (getDiscoveryThunk.rejected.match(resultAction)) {
            throw new Error(resultAction.error.message || 'Error fetching discovery')
          }

          const updatedDiscovery = resultAction.payload.data[0]
          if (!updatedDiscovery) return

          const isDiscoveryUpdated = isDateAfter(updatedDiscovery.updatedAt || '', updatedAt || '')

          if ('discoveryText' in updatedDiscovery && updatedDiscovery.discoveryText && isDiscoveryUpdated) {
            AILoading(false)
            clearInterval(pollingInterval.current!)
          }
        }
      } catch (error) {
        console.error('Polling error:', error)
        AILoading(false)
        clearInterval(pollingInterval.current!)
      }
    }, 5000)
  }

  const handleNext = (currentKey: string, field: EDiscoveryDataField, value: IWizardType[]) => {
    const nextKey = (parseInt(currentKey) + 1).toString()
    setActiveKey(nextKey)

    const updatedField: IWizardType[] = value

    dispatch(
      updateDiscovery({
        discoveryData: {
          ...discovery?.discoveryData,
          [field]: updatedField,
        },
      }),
    )

    setCompletedKeys((prev) => {
      if (prev.includes(currentKey)) {
        return prev
      }
      return [...prev, currentKey]
    })
  }

  const handleChange = (key: string | string[]) => {
    setActiveKey(key as string)
  }

  const handleCompleteStatusChange = (completed: boolean, stepKey: string) => {
    setCompletedKeys((prev) => {
      if (completed) {
        if (prev.includes(stepKey)) {
          return prev
        }
        return [...prev, stepKey]
      } else {
        if (!prev.includes(stepKey)) {
          return prev
        }
        return prev.filter((k) => k !== stepKey)
      }
    })
  }

  const items: AccordionItem[] = [
    {
      key: '1',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <GeneralInformationIcon style={{ marginRight: 8 }} />,
      children: (
        <GeneralInformation
          value={discovery?.discoveryData.generalInformation}
          onNext={(data) => handleNext('1', EDiscoveryDataField.GENERAL_INFORMATION, data)}
          title={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.TITLE}
          placeholder={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.PLACE_HOLDER}
          onCompleteStatusChange={(completed) => handleCompleteStatusChange(completed, '1')}
        />
      ),
    },
    {
      key: '2',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.CLIENT_REFERENCE.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <ClientReferenceIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={clientReferencesFiles}
          onNext={(data) => handleNext('2', EDiscoveryDataField.CLIENT_REFERENCES, data)}
          title={strings.WIZARD.DISCOVERY.CLIENT_REFERENCE.TITLE}
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.CLIENT_REFERENCE}
          accept={DOCUMENT_FILE_TYPES.join(',')}
        />
      ),
    },
    {
      key: '3',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.TARGET_AUDIENCE.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <TargetAudienceIcon style={{ marginRight: 8 }} />,
      children: (
        <TargetAudience
          value={discovery?.discoveryData.targetAudience}
          onNext={(data) => handleNext('3', EDiscoveryDataField.TARGET_AUDIENCE, data)}
          onCompleteStatusChange={(completed) => handleCompleteStatusChange(completed, '3')}
        />
      ),
    },
    {
      key: '4',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.DISCOVERY_SUMMARY.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <DiscoverySummaryIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={discoverySummaryFiles}
          onNext={(data) => handleNext('4', EDiscoveryDataField.DISCOVERY_SUMMARY, data)}
          title={strings.WIZARD.DISCOVERY.DISCOVERY_SUMMARY.TITLE}
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.DISCOVERY_SUMMARY}
          accept={DOCUMENT_FILE_TYPES.join(',')}
        />
      ),
    },
    {
      key: '5',
      label: strings.WIZARD.DISCOVERY.CUSTOMER_CASE_STUDIES.LABEL,
      icon: <CustomerCaseStudiesIcon style={{ marginRight: 8 }} />,

      children: (
        <UploadFilesDropDown
          value={customerCaseStudiesFiles}
          onNext={(data) => handleNext('5', EDiscoveryDataField.CUSTOMER_CASE_STUDIES, data)}
          title={strings.WIZARD.DISCOVERY.CUSTOMER_CASE_STUDIES.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.CUSTOMER_CASE_STUDIES.PLACE_HOLDER}
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.CUSTOMER_CASE_STUDIES}
          accept={DOCUMENT_FILE_TYPES.join(',')}
        />
      ),
    },
    {
      key: '6',
      label: strings.WIZARD.DISCOVERY.PROOF.LABEL,
      icon: <ProofIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={proofFiles}
          onNext={(data) => handleNext('6', EDiscoveryDataField.PROOF, data)}
          title={strings.WIZARD.DISCOVERY.PROOF.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.PROOF.PLACE_HOLDER}
          isTextExplanation
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.PROOF}
          accept={DOCUMENT_FILE_TYPES.join(',')}
        />
      ),
    },
  ]

  if (isAddMoreInfo || discovery?.discoveryData.moreInformation?.length) {
    items.push({
      key: '7',
      label: strings.WIZARD.DISCOVERY.ADD_MORE_INFORMATION.LABEL,
      icon: <AddMoreInfoIcon style={{ marginRight: 8 }} />,
      children: (
        <GeneralInformation
          value={discovery?.discoveryData.moreInformation}
          onNext={(data) => handleNext('7', EDiscoveryDataField.MORE_INFORMATION, data)}
          title={strings.WIZARD.DISCOVERY.ADD_MORE_INFORMATION.TITLE}
          placeholder={strings.WIZARD.DISCOVERY.ADD_MORE_INFORMATION.PLACE_HOLDER}
          onCompleteStatusChange={(completed) => handleCompleteStatusChange(completed, '7')}
        />
      ),
    })
  }

  if (isAddMoreLinksAndFiles) {
    items.push({
      key: '8',
      label: strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.LABEL,
      icon: <AddMoreFilesIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={additionalFiles}
          onNext={(data) => handleNext('8', EDiscoveryDataField.MORE_FILES, data)}
          title={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.PLACE_HOLDER}
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.ADDITIONAL_FILES}
          accept={DOCUMENT_FILE_TYPES.join(',')}
        />
      ),
    })
  }

  const requiredKeys = ['1', '2', '3', '4']
  const availableGenerate: boolean = requiredKeys.every((key) => completedKeys.includes(key))

  return (
    <WizardStepGenericComponent
      title={strings.WIZARD.DISCOVERY.TITLES.TITLE}
      subTitle={strings.WIZARD.DISCOVERY.TITLES.SUBTITLE}
      availableGenerate={availableGenerate}
      generate={GenerateDiscovery}
      isAddMoreInfo
      addMoreInfo={() => setIsAddMoreInfo(true)}
      addMoreLinksAndFiles={() => setIsAddMoreLinksAndFiles(true)}
    >
      {items.map((item) => (
        <Collapse
          key={item.key}
          className={styles.panel}
          activeKey={activeKey}
          expandIconPosition="end"
          accordion
          onChange={(key) => handleChange(key)}
          style={{ alignItems: 'center' }}
        >
          <Collapse.Panel
            key={item.key}
            header={
              <div className={styles.dropDownHeader}>
                {item.icon}
                {item.label}
                {completedKeys.includes(item.key) && (
                  <div className={styles.completeBadge}>
                    <CompletedGreenIcon />
                    <p className={styles.badgeTitle}>{strings.GENERAL_LABELS.COMPLETED}</p>
                  </div>
                )}
              </div>
            }
          >
            {item.children}
          </Collapse.Panel>
        </Collapse>
      ))}
    </WizardStepGenericComponent>
  )
}

export default WizardDiscovery
