import { apiEndpoints } from '../constants/api-endpoints'
import { EGuidelineType } from '../models/enums/guideline-type.enum'
import { IGuideline } from '../models/interfaces/guidelines.interface'
import { ICreateGuidelineResponse, IGetGuidelineResponse } from '../models/interfaces/responses/guidelines-responses'
import { apiRequest } from '../utils/api-utils/api'

const getGuidelineService = async (
  referenceType: EGuidelineType,
  referenceId: string,
): Promise<IGetGuidelineResponse> => {
  const queryParams = `?referenceType=${referenceType}&referenceId=${referenceId}`
  return apiRequest('GET', `${apiEndpoints.GUIDELINES.GET_GUIDELINE}${queryParams}`)
}

const createGuidelineService = async (guidelineData: Partial<IGuideline>): Promise<ICreateGuidelineResponse> => {
  return apiRequest('POST', `${apiEndpoints.GUIDELINES.CREATE_GUIDELINE}`, guidelineData)
}

export { createGuidelineService, getGuidelineService }
