import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { PAGINATION_CONFIG } from '../../models/consts/pagination-config'
import { IProject } from '../../models/interfaces/project.interface'
import { ICreateProjectResponse, IGetProjectsResponse } from '../../models/interfaces/responses/projects-responses'
import { createProjectService, getUserProjects } from '../../services/projects.service'

interface ProjectsState {
  projects: IProject[]
  isProjectsLoading: boolean
  isCreatingProject: boolean
  projectsError: string | null
  page: number
  limit: number
  itemsCount: number
  pagesCount: number
}

const initialState: ProjectsState = {
  projects: [],
  isProjectsLoading: true,
  isCreatingProject: false,
  projectsError: null,
  page: PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: PAGINATION_CONFIG.DEFAULT_LIMIT,
  itemsCount: 0,
  pagesCount: 1,
}

export const fetchProjectsThunk = createAsyncThunk<
  IGetProjectsResponse,
  { userId: string; page?: number; limit?: number }
>(
  'projects/fetchProjects',
  async ({ userId, page = PAGINATION_CONFIG.DEFAULT_PAGE, limit = PAGINATION_CONFIG.DEFAULT_LIMIT }) => {
    const projects = await getUserProjects(userId, page, limit)
    return projects
  },
)

export const createProjectThunk = createAsyncThunk<
  ICreateProjectResponse,
  { name: string; businessUnitId: string; userIds: string[] }
>('projects/createProject', async ({ name, businessUnitId, userIds }) => {
  const response = await createProjectService(name, businessUnitId, userIds)
  return response
})

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    clearProjects: (state) => {
      state.projects = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsThunk.pending, (state) => {
        state.isProjectsLoading = true
        state.projectsError = null
      })
      .addCase(fetchProjectsThunk.fulfilled, (state, action: PayloadAction<IGetProjectsResponse>) => {
        state.isProjectsLoading = false
        state.projects = action.payload.data.projects
        state.itemsCount = action.payload.data.meta.itemsCount
        state.pagesCount = action.payload.data.meta.pagesCount
      })
      .addCase(fetchProjectsThunk.rejected, (state, action) => {
        state.isProjectsLoading = false
        state.projectsError = action.error.message || 'Failed to fetch projects'
      })
      .addCase(createProjectThunk.pending, (state) => {
        state.isCreatingProject = true
        state.projectsError = null
      })
      .addCase(createProjectThunk.fulfilled, (state) => {
        state.isCreatingProject = false
      })
      .addCase(createProjectThunk.rejected, (state, action) => {
        state.isCreatingProject = false
        state.projectsError = action.error.message || 'Failed to create project'
      })
  },
})

export const { clearProjects } = projectsSlice.actions

export default projectsSlice.reducer
