export const getCompetitorsNamesTitles = (competitorsNames: string[]): string[] => {
  // Returns an array of competitor headings
  return competitorsNames.map((name) => `<h2><strong><u>${name}:</u></strong></h2>`)
}

export const styleTextArray = (text: string, competitorsNames: string[]): string => {
  // Replace newlines with <br> tags
  let textWithBreaks = text.replace(/\n/g, '<br>')

  // Convert sub-titles to <h3> headings.
  const titlesToH3 = [
    'Keywords',
    'Key stats/Proof/Customer stories',
    'Use cases',
    'Supporting benefits/capabilities',
    'Key customer benefits/Outcomes',
    'Value proposition',
    'Main ideas/Headlines',
  ]
  titlesToH3.forEach((title) => {
    const regex = new RegExp(`(?<!<h3><strong>)\\b${title}\\b(?!:</strong></h3>)`, 'gi')
    textWithBreaks = textWithBreaks.replace(regex, `<h3><strong>${title}:</strong></h3>`)
  })

  // Split by competitor headings (if they exist) or by <hr> delimiters.
  const sections = textWithBreaks
    .split(/<hr\s*\/?>/gi)
    .map((section) => section.trim())
    .filter((section) => section.length > 0)

  // Remove any existing competitor headings from each section.
  const cleanedSections = sections.map((section) => {
    return section.replace(/<h2><strong><u>.*?:<\/u><\/strong><\/h2>/gi, '').trim()
  })

  // Generate competitor headings.
  const headings = getCompetitorsNamesTitles(competitorsNames)

  let result = ''
  for (let i = 0; i < competitorsNames.length; i++) {
    // Prepend our heading to the cleaned section.
    const sectionContent = headings[i] + (cleanedSections[i] || '')
    result += `<p>${sectionContent}</p>`
    if (i < competitorsNames.length - 1) {
      result += '<hr>'
    }
  }

  return result
}

export const styleText = (text: string): string => {
  return text.replace(/\n/g, '<br>')
}
