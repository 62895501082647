import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Spin, message } from 'antd'
import { GenericButton } from '../../components/GenericButton/GenericButton'
import WizardCompetitiveAnalysis from '../../components/WizardComponents/WizardCompetitiveAnalysis/WizardCompetitiveAnalysis'
import WizardDiscovery from '../../components/WizardComponents/WizardDiscovery/WizardDiscovery'
import { WizardHeader } from '../../components/WizardComponents/WizardHeader/WizardHeader'
import WizardMessagingEngine from '../../components/WizardComponents/WizardMessagingEngine/WizardMessagingEngine'
import { WizardStepper } from '../../components/WizardComponents/WizardStepper/WizardStepper'
import WizardTextEditor from '../../components/WizardComponents/WizardTextEditor/WizardTextEditor'
import { customIcon } from '../../models/consts/spinner'
import {
  getCompetitiveAnalysisThunk,
  getDiscoveryThunk,
  getProjectThunk,
  resetProject,
  updateCompetitiveAnalysisTextThunk,
  updateDiscovery,
  updateDiscoveryTextThunk,
  updateMessagingEngineTextThunk,
} from '../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import styles from './Wizard.module.scss'

const Wizard: FC = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector((state) => state.wizard.data?.project)
  const discovery = useAppSelector((state) => state.wizard.data?.discovery)
  const messagingEngine = useAppSelector((state) => state.wizard.data?.messagingEngine)
  const competitiveAnalysis = useAppSelector((state) => state.wizard.data?.competitiveAnalysis)
  const { projectId } = useParams<{ projectId: string }>()

  const [companiesNames, setCompaniesNames] = useState<string[]>([])
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [discoveryEditorText, setDiscoveryEditorText] = useState<string>(discovery?.discoveryText || '')
  const [competitiveAnalysisEditorText, setCompetitiveAnalysisEditorText] = useState<string[]>(
    competitiveAnalysis?.competitorsText || [''],
  )
  const [messagingEngineEditorText, setMessagingEngineEditorText] = useState<string>(
    messagingEngine?.messagingEngineText || '',
  )
  const [isHeaderLoading, setIsHeaderLoading] = useState(true)
  const [isContentLoading, setIsContentLoading] = useState(true)
  const [AILoading, setAILoading] = useState(false)

  useEffect(() => {
    if (discovery?.discoveryText) {
      setDiscoveryEditorText(discovery?.discoveryText)
    }
  }, [discovery?.discoveryText])

  useEffect(() => {
    if (messagingEngine?.messagingEngineText) {
      setMessagingEngineEditorText(messagingEngine.messagingEngineText)
    }
  }, [messagingEngine?.messagingEngineText])

  useEffect(() => {
    if (projectId) {
      setIsHeaderLoading(true)
      try {
        dispatch(getProjectThunk({ projectId }))
      } catch (error) {
        console.error(error)
      } finally {
        setIsHeaderLoading(false)
      }
    }

    return () => {
      dispatch(resetProject())
    }
  }, [dispatch, projectId])

  useEffect(() => {
    if (project?.messaging?.id) {
      dispatch(getCompetitiveAnalysisThunk({ messagingId: project.messaging.id }))
    }
  }, [dispatch, project?.messaging?.id])

  const goToNextStep = () => {
    const previousStep = currentStep
    setCurrentStep(currentStep + 1)

    handleTextSave().catch((error) => {
      message.error('Saving failed. Reverting to the previous step.')
      setCurrentStep(previousStep)
    })
  }
  const goToPreviousStep = () => {
    const previousStep = currentStep
    setCurrentStep(currentStep - 1)

    handleTextSave().catch((error) => {
      message.error('Saving failed. Reverting to the previous step.')
      setCurrentStep(previousStep)
    })
  }

  const handleUpdateFirstCompetitorText = (updatedVal: string | string[]) => {
    const fullText = Array.isArray(updatedVal) ? updatedVal.join('<hr>') : updatedVal
    const splitTexts = fullText.split(/<hr\s*\/?>/gi)
    setCompetitiveAnalysisEditorText(splitTexts)
  }

  const handleTextSave = async () => {
    try {
      if (currentStep === 0 && discovery?.id) {
        await dispatch(
          getDiscoveryThunk({
            messagingId: discovery.messagingId,
          }),
        )
        dispatch(updateDiscovery({ discoveryText: discoveryEditorText }))
        await dispatch(
          updateDiscoveryTextThunk({
            discoveryId: discovery.id,
            discoveryText: discoveryEditorText,
          }),
        )
      } else if (currentStep === 1 && competitiveAnalysis?.competitors && competitiveAnalysis.id) {
        const competitiveAnalysisId = competitiveAnalysis.id
        const updateCompetitorsPromises = competitiveAnalysis.competitors.map((_, index) =>
          dispatch(
            updateCompetitiveAnalysisTextThunk({
              competitiveAnalysisId: competitiveAnalysisId,
              index: index,
              text: competitiveAnalysisEditorText[index],
            }),
          ),
        )
        const updateCompetitorsResults = await Promise.all(updateCompetitorsPromises)

        const allFulfilled = updateCompetitorsResults.every((result) =>
          updateCompetitiveAnalysisTextThunk.fulfilled.match(result),
        )

        if (!allFulfilled) {
          updateCompetitorsResults.forEach((result, index) => {
            if (updateCompetitiveAnalysisTextThunk.rejected.match(result)) {
              console.error(`Error in updateCompetitiveAnalysisTextThunk for competitor ${index + 1}:`, result.error)
            }
          })
        }
      } else if (currentStep === 2 && messagingEngine?.id) {
        dispatch(
          updateMessagingEngineTextThunk({
            messagingEngineId: messagingEngine.id,
            text: messagingEngineEditorText,
          }),
        )
      }
    } catch (error) {
      message.error('An unexpected error occurred while saving. Please try again.')
    }
  }

  const handleStepClick = async (step: number) => {
    const previousStep = currentStep
    setCurrentStep(step)

    try {
      await handleTextSave()
    } catch (error) {
      message.error('Saving failed. Reverting to the previous step.')
      setCurrentStep(previousStep)
    }
  }
  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <WizardDiscovery
              onLoading={setIsContentLoading}
              messagingId={project?.messaging?.id}
              AILoading={setAILoading}
              project={project}
            />
            <WizardTextEditor
              onChangeText={setDiscoveryEditorText}
              text={discovery?.discoveryText}
              step={currentStep}
              loading={AILoading}
            />
          </>
        )
      case 1:
        return (
          <>
            <WizardCompetitiveAnalysis
              onLoading={setIsContentLoading}
              messagingId={project?.messaging?.id}
              AILoading={setAILoading}
              emitCompaniesNames={setCompaniesNames}
            />
            <WizardTextEditor
              onChangeText={handleUpdateFirstCompetitorText}
              text={competitiveAnalysis?.competitorsText}
              step={currentStep}
              loading={AILoading}
              companiesNames={companiesNames}
            />
          </>
        )
      case 2:
        return (
          <>
            <WizardMessagingEngine
              messagingId={project?.messaging?.id}
              businessUnitId={project?.businessUnit?.id}
              AILoading={setAILoading}
              onLoading={setIsContentLoading}
            />
            <WizardTextEditor
              onChangeText={setMessagingEngineEditorText}
              text={messagingEngine?.messagingEngineText}
              loading={AILoading}
              step={currentStep}
            />
          </>
        )
      default:
        return null
    }
  }
  return (
    <>
      <Spin className={styles.spinner} spinning={isContentLoading || isHeaderLoading} indicator={customIcon}>
        <main className={styles.wizardContainer}>
          <WizardHeader saveChanges={handleTextSave} project={project} onLoading={setIsHeaderLoading} />
          <hr className={styles.hrBorder} />
          <div className={styles.midContainer}>
            <div className={styles.stepperContainer}>
              <WizardStepper currentStep={currentStep} onStepChange={handleStepClick} />
            </div>
            <div className={styles.buttonsContainer}>
              <GenericButton isDisabled={currentStep === 0} title="Back" secondary onClick={goToPreviousStep} />
              <GenericButton isDisabled={currentStep === 2} title="Next" onClick={goToNextStep} />
            </div>
          </div>
          <div className={styles.bottomContainer}>{renderStepContent()}</div>
        </main>
      </Spin>
    </>
  )
}
export default Wizard
