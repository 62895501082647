import { IBusinessUnit } from '../../models/interfaces/business-unit.interface'
import { IClient } from '../../models/interfaces/client.interface'
import { IUser } from '../../models/interfaces/users.interface'
import { fetchBusinessUnitsService } from '../../services/business-unit.service'
import { fetchClientsService } from '../../services/clients.service'
import { fetchAllUsers } from '../../services/users.service'

const MAX_ITEMS = 1000

export const handleFetchClients = async (setClients: (clients: IClient[]) => void) => {
  const response = await fetchClientsService(1, MAX_ITEMS)
  setClients(response.data.clients)
}

export const handleFetchBusinessUnits = async (
  clientId: string,
  setBusinessUnits: (businessUnits: IBusinessUnit[]) => void,
) => {
  const filter = [
    {
      field: 'clientId',
      value: clientId,
    },
  ]
  const response = await fetchBusinessUnitsService(1, MAX_ITEMS, filter)
  setBusinessUnits(response.data.businessUnits)
}

export const handleFetchUsers = async (setMembers: (users: IUser[]) => void) => {
  const users = await fetchAllUsers()
  setMembers(users.data)
}
