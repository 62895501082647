import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PAGINATION_CONFIG } from '../../models/consts/pagination-config'
import { IBusinessUnit } from '../../models/interfaces/business-unit.interface'
import {
  ICreateBusinessUnitResponse,
  IGetBusinessUnitsResponse,
} from '../../models/interfaces/responses/business-units-responses'
import { createBusinessUnitService, fetchBusinessUnitsService } from '../../services/business-unit.service'

interface IBusinessUnitState {
  businessUnits: IBusinessUnit[]
  loading: boolean
  isCreatingBusinessUnit: boolean
  businessUnitsError: string | null
  page: number
  limit: number
  itemsCount: number
  pagesCount: number
}

const initialState: IBusinessUnitState = {
  businessUnits: [],
  loading: false,
  isCreatingBusinessUnit: false,
  businessUnitsError: null,
  page: PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: PAGINATION_CONFIG.DEFAULT_LIMIT,
  itemsCount: 1,
  pagesCount: 1,
}

export const fetchBusinessUnitsThunk = createAsyncThunk<
  IGetBusinessUnitsResponse,
  {
    clientId: string
    page?: number
    limit?: number
    filter?: { field: string; value: string }[]
    sort?: { field: string; order: 'ASC' | 'DESC' }
    search?: string
  }
>(
  'businessUnit/fetchBusinessUnits',
  async ({
    clientId,
    page = PAGINATION_CONFIG.DEFAULT_PAGE,
    limit = PAGINATION_CONFIG.DEFAULT_LIMIT,
    filter = [],
    sort,
    search,
  }) => {
    const defaultFilter = [{ field: 'clientId', value: clientId }]
    const combinedFilter = [...defaultFilter, ...filter]
    return await fetchBusinessUnitsService(page, limit, combinedFilter, sort, search)
  },
)

export const createBusinessUnitThunk = createAsyncThunk<
  ICreateBusinessUnitResponse,
  { name: string; clientId: string }
>('businessUnit/createBusinessUnit', async ({ name, clientId }) => {
  return await createBusinessUnitService(name, clientId)
})

const businessUnitSlice = createSlice({
  name: 'businessUnit',
  initialState,
  reducers: {
    clearBusinessUnits: (state) => {
      state.businessUnits = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessUnitsThunk.pending, (state) => {
        state.loading = true
        state.businessUnitsError = null
      })
      .addCase(fetchBusinessUnitsThunk.fulfilled, (state, action: PayloadAction<IGetBusinessUnitsResponse>) => {
        state.loading = false
        state.businessUnits = action.payload.data.businessUnits
        state.itemsCount = action.payload.data.meta.itemsCount
        state.pagesCount = action.payload.data.meta.pagesCount
      })
      .addCase(fetchBusinessUnitsThunk.rejected, (state, action) => {
        state.loading = false
        state.businessUnitsError = action.error.message || 'Failed to fetch business units'
      })
      .addCase(createBusinessUnitThunk.pending, (state) => {
        state.isCreatingBusinessUnit = true
        state.businessUnitsError = null
      })
      .addCase(createBusinessUnitThunk.fulfilled, (state, action: PayloadAction<ICreateBusinessUnitResponse>) => {
        state.isCreatingBusinessUnit = false
      })
      .addCase(createBusinessUnitThunk.rejected, (state, action) => {
        state.isCreatingBusinessUnit = false
        state.businessUnitsError = action.error.message || 'Failed to create business unit'
      })
  },
})

export const { clearBusinessUnits } = businessUnitSlice.actions

export default businessUnitSlice.reducer
