import { apiEndpoints } from '../constants/api-endpoints'
import { PAGINATION_CONFIG } from '../models/consts/pagination-config'
import { IUpdateProjectPayload } from '../models/interfaces/requests/update-project-payload.interface'
import {
  ICreateProjectResponse,
  IGetBusinessUnitProjectsResponse,
  IGetProjectResponse,
  IGetProjectsResponse,
  IUpdateProjectResponse,
} from '../models/interfaces/responses/projects-responses'
import { apiRequest } from '../utils/api-utils/api'

const createProjectService = async (
  name: string,
  businessUnitId: string,
  userIds: string[],
): Promise<ICreateProjectResponse> => {
  return await apiRequest('POST', apiEndpoints.PROJECTS.CREATE_PROJECT, {
    name,
    businessUnitId,
    userIds,
  })
}

const getProjectService = async (projectId: string): Promise<IGetProjectResponse> => {
  return await apiRequest('GET', apiEndpoints.PROJECTS.GET_PROJECT(projectId))
}

const getUserProjects = async (
  userId: string,
  page: number = PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: number = PAGINATION_CONFIG.DEFAULT_LIMIT,
  sort?: { field: string; order: 'ASC' | 'DESC' },
  filter?: { field: string; value: string }[],
): Promise<IGetProjectsResponse> => {
  const payload = {
    userId,
    page,
    limit,
    sort,
    filter,
  }
  return await apiRequest('POST', apiEndpoints.PROJECTS.GET_PROJECTS, payload)
}

const deleteProjectService = async (id: string): Promise<void> => {
  const payload = {
    id,
  }
  return await apiRequest('DELETE', apiEndpoints.PROJECTS.DELETE_PROJECT, payload)
}

const updateProjectService = async (
  projectId: string,
  payload: IUpdateProjectPayload,
): Promise<IUpdateProjectResponse> => {
  return await apiRequest('PATCH', apiEndpoints.PROJECTS.UPDATE_PROJECT(projectId), payload)
}

const getBusinessUnitProjectsService = async (
  businessUnitId: string,
  page: number = PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: number = PAGINATION_CONFIG.DEFAULT_LIMIT,
  sort?: { field: string; order: 'ASC' | 'DESC' },
  search?: string,
): Promise<IGetBusinessUnitProjectsResponse> => {
  const payload = {
    page,
    limit,
    sort,
    filter: [{ field: 'businessUnitId', value: businessUnitId }],
    search,
  }
  return await apiRequest('POST', apiEndpoints.PROJECTS.GET_PROJECTS, payload)
}

export {
  createProjectService,
  deleteProjectService,
  getBusinessUnitProjectsService,
  getProjectService,
  getUserProjects,
  updateProjectService,
}
