import { Collapse, message } from 'antd'
import { FC, useRef, useEffect, useState } from 'react'

import { AddMoreFilesIcon, CompletedGreenIcon, GeneralInformationIcon } from '../../../assets/icons'
import { DOCUMENT_FILE_TYPES } from '../../../models/consts/document-type'
import { strings } from '../../../models/consts/strings'
import { EFileReferenceType, EFileSubReferenceType } from '../../../models/enums/file-reference-type.enum'
import { EGuidelineType } from '../../../models/enums/guideline-type.enum'
import { AccordionItem } from '../../../models/interfaces/accordion-item.interface'
import { IMessagingEnginePayload } from '../../../models/interfaces/requests/messaging-engine-payload.interface'
import { IWizardType } from '../../../models/interfaces/wizard-type.interface'
import { fetchGuidelineThunk } from '../../../store/slices/guideline.slice'
import {
  createOrUpdateMessagingEngine,
  getDiscoveryThunk,
  getMessagingEngineThunk,
  updateMessagingEngine,
} from '../../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { isDateAfter } from '../../../utils/date.util'
import GeneralInformation from '../WizardDiscovery/GeneralInformation/GeneralInformation'
import UploadFilesDropDown from '../WizardDiscovery/UploadFilesDropDown/UploadFilesDropDown'
import WizardStepGenericComponent from '../WizardStepGenericComponent/WizardStepGenericComponent'
import styles from './WizardMessagingEngine.module.scss'

interface IWizardMessagingEngineProps {
  messagingId?: string
  businessUnitId?: string
  AILoading: (isLoading: boolean) => void
  onLoading: (isLoading: boolean) => void
}
const WizardMessagingEngine: FC<IWizardMessagingEngineProps> = ({
  messagingId,
  businessUnitId,
  AILoading,
  onLoading,
}) => {
  const dispatch = useAppDispatch()
  const messagingEngine = useAppSelector((state) => state.wizard.data?.messagingEngine)
  const competitiveAnalysis = useAppSelector((state) => state.wizard.data?.competitiveAnalysis)
  const discovery = useAppSelector((state) => state.wizard.data.discovery)
  const guideline = useAppSelector((state) => state.guidelineSlice.guideline)
  const pollingInterval = useRef<NodeJS.Timeout | null>(null)

  const [activeKey, setActiveKey] = useState<string>('')
  const [completedKeys, setCompletedKeys] = useState<string[]>([])

  useEffect(() => {
    if (!messagingId) return
    ;(async () => {
      try {
        onLoading(true)
        await dispatch(getMessagingEngineThunk({ messagingId }))
      } catch (error) {
        message.error('Error fetching messaging engine')
        console.error('Error: ', error)
      } finally {
        onLoading(false)
      }
    })()
  }, [messagingId, dispatch, onLoading])

  useEffect(() => {
    if (!messagingId) return
    ;(async () => {
      try {
        onLoading(true)
        if (!guideline && businessUnitId) {
          await dispatch(
            fetchGuidelineThunk({
              referenceType: EGuidelineType.BUSINESSUNIT,
              referenceId: businessUnitId,
            }),
          )
        }
        if (!discovery) {
          await dispatch(getDiscoveryThunk({ messagingId }))
        }
      } catch (error) {
        console.error('Error fetching guideline/discovery:', error)
      } finally {
        onLoading(false)
      }
    })()
  }, [messagingId, businessUnitId, guideline, discovery, dispatch, onLoading])

  useEffect(() => {
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current)
      }
    }
  }, [])

  const generateMessagingEngine = async () => {
    if (messagingId && competitiveAnalysis?.competitorsText?.length && discovery?.metadata && messagingEngine) {
      const competitorsData = competitiveAnalysis.competitorsText.map((text) => ({
        type: 'text',
        value: text,
        text: text,
      }))

      const messagingEngineData = {
        discoveryMetadata: {
          ...discovery.metadata,
        },
        competitiveAnalysis: competitorsData,
      }

      const payload: IMessagingEnginePayload = {
        id: messagingEngine.id,
        messagingId,
        messagingEngineData,
      }

      AILoading(true)

      try {
        const resultAction = await dispatch(createOrUpdateMessagingEngine(payload))
        if (createOrUpdateMessagingEngine.rejected.match(resultAction)) {
          throw new Error('Error while generating AI')
        }
        pollForUpdatedMessagingEngine(resultAction?.payload?.data?.updatedAt)
      } catch (error) {
        AILoading(false)
        console.error('Error creating or updating messaging engine:', error)
        message.error('Something went wrong while generating messaging engine')
      }
    } else {
      const missingFields: string[] = []
      if (!messagingId) missingFields.push('Messaging ID')
      if (!discovery?.metadata) missingFields.push('Discovery')
      if (!competitiveAnalysis?.competitorsText || competitiveAnalysis?.competitorsText?.length === 0)
        missingFields.push('Competitive Analysis')
      if (!messagingEngine) missingFields.push('Messaging Engine')

      message.error(`Some information is missing: ${missingFields.join(', ')}`)
    }
  }

  const pollForUpdatedMessagingEngine = (updatedAt: string | undefined) => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current)
    }

    pollingInterval.current = setInterval(async () => {
      try {
        if (messagingId) {
          const resultAction = await dispatch(getMessagingEngineThunk({ messagingId }))

          if (getMessagingEngineThunk.rejected.match(resultAction)) {
            throw new Error(resultAction.error.message || 'Error fetching discovery')
          }

          const updatedMessagingEngine = resultAction.payload.data[0]
          if (!updatedMessagingEngine) return

          const isMessagingEngineUpdated = isDateAfter(updatedMessagingEngine.updatedAt || '', updatedAt || '')

          if (
            'messagingEngineText' in updatedMessagingEngine &&
            updatedMessagingEngine.messagingEngineText &&
            isMessagingEngineUpdated
          ) {
            AILoading(false)
            clearInterval(pollingInterval.current!)
          }
        }
      } catch (error) {
        console.error('Polling error:', error)
        AILoading(false)
        clearInterval(pollingInterval.current!)
      }
    }, 5000)
  }

  const handleNext = (currentKey: string, value: IWizardType[]) => {
    if (!messagingEngine) return
    const nextKey = (parseInt(currentKey) + 1).toString()
    setActiveKey(nextKey)

    const updatedMessagingEngineData = {
      ...messagingEngine.messagingEngineData,
      ...(currentKey === '1' ? { moreInformation: value } : { files: value }),
    }

    dispatch(
      updateMessagingEngine({
        messagingEngineData: updatedMessagingEngineData,
      }),
    )

    setCompletedKeys((prev) => {
      const updated = [...new Set([...prev, currentKey])]
      return updated
    })
  }

  const handleChange = (key: string | string[]) => {
    setActiveKey(key as string)
  }

  const items: AccordionItem[] = [
    {
      key: '1',
      label: <>{strings.WIZARD.MESSAGING_ENGINE.ADD_MORE_INFORMATION}</>,
      icon: <GeneralInformationIcon style={{ marginRight: 8 }} />,
      children: (
        <GeneralInformation
          value={messagingEngine?.messagingEngineData.moreInformation}
          onNext={(data) => handleNext('1', data)}
          title={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.TITLE}
          placeholder={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.PLACE_HOLDER}
        />
      ),
    },
    {
      key: '2',
      label: strings.WIZARD.MESSAGING_ENGINE.ADD_MORE_LINKS_AND_FILES,
      icon: <AddMoreFilesIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={messagingEngine?.messagingEngineData.files}
          onNext={(data) => handleNext('2', data)}
          title={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.PLACE_HOLDER}
          referenceId={messagingId}
          referenceType={EFileReferenceType.MESSAGING_ENGINE}
          subReferenceType={EFileSubReferenceType.ADDITIONAL_FILES}
          accept={DOCUMENT_FILE_TYPES.join(',')}
        />
      ),
    },
  ]

  return (
    <WizardStepGenericComponent
      title={strings.WIZARD.MESSAGING_ENGINE.TITLE}
      subTitle={strings.WIZARD.MESSAGING_ENGINE.SUB_TITLE}
      availableGenerate={true}
      generate={generateMessagingEngine}
      onlyGenerateButton
    >
      {items.map((item) => (
        <Collapse
          key={item.key}
          className={styles.panel}
          activeKey={activeKey}
          expandIconPosition="end"
          accordion
          onChange={(key) => handleChange(key)}
          style={{ alignItems: 'center' }}
        >
          <Collapse.Panel
            key={item.key}
            header={
              <div className={styles.dropDownHeader}>
                {item.icon}
                {item.label}
                {completedKeys.includes(item.key) && (
                  <div className={styles.completeBadge}>
                    <CompletedGreenIcon />
                    <p className={styles.badgeTitle}>{strings.GENERAL_LABELS.COMPLETED}</p>
                  </div>
                )}
              </div>
            }
          >
            {item.children}
          </Collapse.Panel>
        </Collapse>
      ))}
    </WizardStepGenericComponent>
  )
}

export default WizardMessagingEngine
