import { DownOutlined } from '@ant-design/icons'
import { Input, Select } from 'antd'
import { FC, useEffect, useState } from 'react'

import { IUser } from '../../../models/interfaces/users.interface'
import { ValueType, optionsType } from '../../../models/unions/modal-input.union'
import styles from './ModalInput.module.scss'
import { UserOption } from './UserOption/UserOption'
import { lengths } from '../../../models/consts/lengths.constants'

const { Option } = Select

interface IModalInputProps {
  label: string
  placeholder: string
  value: ValueType
  onChange: (value: any) => void
  options?: optionsType[]
  isMultiple?: boolean
  isDisabled?: boolean
  onFocus?: () => void
  connectedUser: IUser
  isMembersInput?: boolean
}

const ModalInput: FC<IModalInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  options,
  isMultiple = false,
  isDisabled = false,
  onFocus,
  connectedUser,
  isMembersInput = false,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<optionsType[]>([])

  useEffect(() => {
    if (options) {
      setFilteredOptions(options.filter((option) => option?.id !== connectedUser.id))
    }
  }, [options, connectedUser.id])

  const handleSearch = (searchTerm: string) => {
    if (options) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase()

      const filtered = options
        .filter((option) => option?.id !== connectedUser.id)
        .filter((option) =>
          option && 'firstName' in option && 'lastName' in option
            ? `${option.firstName} ${option.lastName}`.toLowerCase().includes(lowerCaseSearchTerm)
            : option?.name.toLowerCase().includes(lowerCaseSearchTerm),
        )

      setFilteredOptions(filtered)
    }
  }

  return (
    <div className={styles.fieldContainer}>
      <label className={styles.modalLabelText}>{label}</label>
      {options ? (
        <Select
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="dropdown-modal"
          suffixIcon={<DownOutlined />}
          mode={isMultiple ? 'multiple' : undefined}
          disabled={isDisabled}
          onFocus={onFocus}
          showSearch
          onSearch={handleSearch}
          filterOption={false}
          listHeight={150}
        >
          {isMembersInput && (
            <Option key={connectedUser.id} value={connectedUser.id} disabled>
              <UserOption user={{ firstName: 'You', lastName: '' }} />
            </Option>
          )}

          {filteredOptions?.map((option) => {
            if (option && 'firstName' in option && 'lastName' in option) {
              return (
                <Option key={option.id} value={option.id}>
                  <UserOption
                    user={{
                      firstName: option.firstName,
                      lastName: option.lastName,
                    }}
                  />
                </Option>
              )
            } else if (option && 'name' in option) {
              return (
                <Option key={option.id} value={option.name}>
                  {option.name}
                </Option>
              )
            }
            return null
          })}
        </Select>
      ) : (
        <Input
          className={styles.ModalInput}
          value={value as string}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          maxLength={lengths.MAX_PROJECT_NAME}
        />
      )}
    </div>
  )
}

export { ModalInput }
