import { FC } from 'react'

import { strings } from '../../models/consts/strings'
import styles from './EmptyMsg.module.scss'
import { MissingNewsIcon } from '../../assets/icons'

const EmptyMsg: FC = () => {
  return (
    <div className={styles.emptyData}>
      <MissingNewsIcon />
      <p className={styles.msg}>{strings.EMPTY_CASES.EMPTY_NEWS}</p>
    </div>
  )
}

export { EmptyMsg }
