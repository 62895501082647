import { apiEndpoints } from '../constants/api-endpoints'
import { PAGINATION_CONFIG } from '../models/consts/pagination-config'
import {
  ICreateBusinessUnitResponse,
  IGetBusinessUnitByIdResponse,
  IGetBusinessUnitsResponse,
} from '../models/interfaces/responses/business-units-responses'
import { apiRequest } from '../utils/api-utils/api'

const createBusinessUnitService = async (name: string, clientId: string): Promise<ICreateBusinessUnitResponse> => {
  return await apiRequest('POST', apiEndpoints.BUSINESS_UNITS.CREATE_BUSINESS_UNIT, {
    name,
    clientId,
  })
}

const fetchBusinessUnitsService = async (
  page: number = PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: number = PAGINATION_CONFIG.DEFAULT_LIMIT,
  filter?: { field: string; value: string }[],
  sort?: { field: string; order: 'ASC' | 'DESC' },
  search?: string,
): Promise<IGetBusinessUnitsResponse> => {
  const payload = {
    page,
    limit,
    filter,
    sort,
    search,
  }
  return await apiRequest('POST', apiEndpoints.BUSINESS_UNITS.GET_BUSINESS_UNITS, payload)
}

const fetchBusinessUnitByIdService = async (businessUnitId: string): Promise<IGetBusinessUnitByIdResponse> => {
  return await apiRequest('GET', apiEndpoints.BUSINESS_UNITS.GET_BUSINESS_UNIT_ById(businessUnitId))
}

export { createBusinessUnitService, fetchBusinessUnitByIdService, fetchBusinessUnitsService }
