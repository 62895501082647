import { Spin, Upload, UploadFile, UploadProps, message } from 'antd'
import type { UploadRequestOption } from 'rc-upload/lib/interface'
import { FC, useEffect, useState } from 'react'

import { FontIcon } from '../../../assets/icons'
import { FONT_FILE_TYPES } from '../../../models/consts/document-type'
import { customIcon } from '../../../models/consts/spinner'
import { strings } from '../../../models/consts/strings'
import { EFileReferenceType, EFileSubReferenceType } from '../../../models/enums/file-reference-type.enum'
import { IFile } from '../../../models/interfaces/file.interface'
import { uploadFileService } from '../../../services/files-upload.service'
import { GenericButton } from '../../GenericButton/GenericButton'
import styles from './FontUpload.module.scss'

const { Dragger } = Upload

interface IFontUploadProps {
  files?: IFile[]
  referenceId?: string
  firstText: string
  secondText: string
  accept?: string
  onFileUpload?: (updatedFiles: IFile[]) => void
}

const FontUpload: FC<IFontUploadProps> = ({
  files = [],
  firstText,
  secondText,
  accept = FONT_FILE_TYPES.join(','),
  referenceId,
  onFileUpload,
}) => {
  const [uploadedFont, setUploadedFont] = useState<string | null>(null)
  const [fontName, setFontName] = useState<string>('Default Font')
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [uploading, setUploading] = useState<boolean>(false)

  useEffect(() => {
    if (!referenceId && files) {
      setFileList([])
      return
    }
    if (!referenceId || !files) return

    const foundFont = files.find((file) => file.subReferenceType === EFileSubReferenceType.FONT)
    if (foundFont) {
      setUploadedFont(foundFont.fileUrl)
      setFontName(foundFont.fileName.split('.')[0])
      setFileList([
        {
          uid: foundFont.id as string,
          name: foundFont.fileName,
          status: 'done' as UploadFile['status'],
          url: foundFont.fileUrl,
          type: foundFont.fileType,
        },
      ])
    }
  }, [referenceId, files])

  useEffect(() => {
    if (uploadedFont) {
      const fontFace = new FontFace(fontName, `url(${uploadedFont})`)
      fontFace
        .load()
        .then(() => {
          document.fonts.add(fontFace)
        })
        .catch((error) => {
          console.error('Error loading font:', error)
        })
    }
  }, [uploadedFont, fontName])

  const handleUploadRequest = async ({ file, onSuccess, onError }: UploadRequestOption) => {
    try {
      if (!referenceId) throw new Error('referenceId is required but was not provided')
      if (!(file instanceof File)) throw new Error('Invalid file type')
      setUploading(true)
      const formData = new FormData()
      formData.append('file', file)

      const response = await uploadFileService(formData)
      onSuccess && onSuccess(response)
    } catch (error) {
      onError?.(error instanceof Error ? error : new Error('Unexpected upload error'))
    } finally {
      setUploading(false)
    }
  }

  const handleChange: UploadProps['onChange'] = (info) => {
    const { file, fileList: newFileList } = info
    setFileList(newFileList)

    if (file.status === 'done') {
      const { fileUrl } = file.response
      if (!fileUrl) {
        return message.error(`No fileUrl found in response for file: ${file.name}`)
      }
      setUploadedFont(fileUrl)
      setFontName(file.name.split('.')[0])

      const newFile: IFile = {
        referenceId: referenceId!,
        referenceType: EFileReferenceType.GUIDELINE,
        subReferenceType: EFileSubReferenceType.FONT,
        fileName: file.name,
        fileType: file.type || '',
        fileUrl,
      }
      let updatedFiles = [...files]
      updatedFiles = updatedFiles.filter((f) => f.subReferenceType !== EFileSubReferenceType.FONT)

      updatedFiles.push(newFile)
      onFileUpload?.(updatedFiles)

      message.success(`${file.name} uploaded successfully`)
    } else if (file.status === 'error') {
      message.error(`${file.name} upload failed`)
    }
  }

  const props: UploadProps = {
    accept,
    fileList,
    customRequest: handleUploadRequest,
    onChange: handleChange,
    showUploadList: false,
  }

  return (
    <div className={styles.fontWrapper}>
      <Spin spinning={uploading} indicator={customIcon}>
        <Dragger style={{ minHeight: '130px' }} {...props}>
          {!uploadedFont ? (
            <div className={styles.draggerContainer}>
              <FontIcon />
              <div className={styles.textContainer}>
                <p className={styles.text1}>{firstText}</p>
                <p className={styles.text2}>{secondText}</p>
              </div>
              <GenericButton secondary title={strings.GUIDELINE.FILES.BUTTON} />
            </div>
          ) : (
            <div className={styles.fontContainer}>
              <p style={{ fontFamily: fontName }} className={styles.fontPreview}>
                Aa
              </p>
              <div style={{ fontFamily: fontName }} className={styles.fontSample}>
                <p className={styles.fontName} style={{ fontFamily: fontName }}>
                  {fontName}
                </p>
                ABCDEFGHIJKLMNOPQRSTUVWXYZ
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890
              </div>
            </div>
          )}
        </Dragger>
      </Spin>
    </div>
  )
}

export default FontUpload
