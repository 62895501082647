import { FC, useEffect, useState } from 'react'

import { CloseIcon } from '../../../../../assets/icons'
import { strings } from '../../../../../models/consts/strings'
import { IUser } from '../../../../../models/interfaces/users.interface'
import { fetchAllUsers } from '../../../../../services/users.service'
import { updateProjectThunk } from '../../../../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../../../../store/store'
import { UserOption } from '../../../../CustomModal/ModalInput/UserOption/UserOption'
import { ModalDropdown } from '../../../../ModalDropdown/ModalDropdown'
import { WizardSearch } from '../../WizardSearch/WizardSearch'
import styles from './MembersModal.module.scss'

interface IMembersModalProps {
  onClose: () => void
  onUpdateMembers: (members: IUser[]) => void
  projectId?: string
  projectMembers?: IUser[]
}
const MembersModal: FC<IMembersModalProps> = ({ onUpdateMembers, onClose, projectId, projectMembers }) => {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector((state) => state.user)

  const [users, setUsers] = useState<IUser[]>([])
  const selectedUsers = projectMembers ? projectMembers.filter((member) => member.id !== currentUser.id) : []
  const membersAssigned = projectMembers ? projectMembers.length : 0

  useEffect(() => {
    ;(async () => {
      try {
        const users = await fetchAllUsers()
        setUsers(users.data.filter((member: IUser) => member.id !== currentUser.id))
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    })()
  }, [currentUser.id])

  const updateMembers = (newMembers: IUser[]) => {
    onUpdateMembers(newMembers)

    const userIds = newMembers.map((u) => u.id)
    if (projectId && !userIds.includes(currentUser.id)) {
      userIds.push(currentUser.id)
    }

    if (projectId) {
      dispatch(
        updateProjectThunk({
          projectId,
          payload: { userIds },
        }),
      ).catch((error) => {
        console.error('Error updating project users:', error)
      })
    }
  }

  const handleSelectUser = (user: IUser) => {
    if (!projectMembers?.find((u) => u.id === user.id)) {
      const newMembers = projectMembers ? [...projectMembers, user] : [currentUser, user]
      updateMembers(newMembers)
    }
  }

  const handleRemoveUser = (userId: string) => {
    if (projectMembers) {
      const newMembers = projectMembers.filter((user) => user.id !== userId)
      updateMembers(newMembers)
    }
  }

  const filteredUsers = users.filter((user) => !selectedUsers.some((selectedUser) => selectedUser.id === user.id))

  return (
    <ModalDropdown>
      <div className={styles.modalContainer}>
        <div className={styles.topContainer}>
          <div className={styles.titles}>
            <h2 className={styles.title}>{strings.WIZARD.HEADER.MEMBERS_TAB.TITLE}</h2>
            <h3 className={styles.secondTitle}>
              {membersAssigned} {membersAssigned > 1 ? 'members assigned' : 'member assigned'}
            </h3>
          </div>
          <div className={styles.buttonBackground}>
            <CloseIcon className={styles.closeButton} onClick={onClose} />
          </div>
        </div>
        <div className={styles.midContainer}>
          <WizardSearch users={filteredUsers} onSelectUser={handleSelectUser} />
        </div>
        <div className={styles.bottomContainer}>
          <UserOption isMeTag user={currentUser} />
          {selectedUsers.map((user) => (
            <div className={styles.userTag} key={user.id}>
              <UserOption user={user} />
              <CloseIcon className={styles.closeIcon} onClick={() => handleRemoveUser(user.id)} />
            </div>
          ))}
        </div>
      </div>
    </ModalDropdown>
  )
}

export { MembersModal }
