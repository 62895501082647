import { apiEndpoints } from '../constants/api-endpoints'
import { PAGINATION_CONFIG } from '../models/consts/pagination-config'
import {
  ICreateClientsResponse,
  IGetClientsResponse,
  IGetIndustriesResponse,
} from '../models/interfaces/responses/clients-responses'
import { apiRequest } from '../utils/api-utils/api'

const fetchClientsService = async (
  page: number = PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: number = PAGINATION_CONFIG.DEFAULT_LIMIT,
  sort?: { field: string; order: 'ASC' | 'DESC' },
  filter?: { field: string; value: string }[],
  search?: string,
): Promise<IGetClientsResponse> => {
  const payload = {
    page,
    limit,
    sort,
    filter,
    search,
  }
  return await apiRequest('POST', apiEndpoints.CLIENTS.GET_CLIENTS, payload)
}

const createClientService = async (name: string, industry: string): Promise<ICreateClientsResponse> => {
  return await apiRequest('POST', apiEndpoints.CLIENTS.CREATE_CLIENT, {
    name,
    industry: industry,
  })
}

const fetchIndustriesService = async (): Promise<IGetIndustriesResponse> => {
  return await apiRequest('GET', apiEndpoints.INDUSTRIES.GET_ALL_INDUSTRIES)
}

export { createClientService, fetchClientsService, fetchIndustriesService }
