import { FC } from 'react'

import { BackIcon } from '../../assets/icons'
import styles from './BackButton.module.scss'

interface IBackButtonProps {
  title: string
  onClick: () => void
}

const BackButton: FC<IBackButtonProps> = ({ title, onClick }) => {
  return (
    <button className={styles.backButton} type="button" onClick={onClick}>
      <BackIcon />
      <span>{title}</span>
    </button>
  )
}

export default BackButton
