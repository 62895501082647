import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ICompetitiveAnalysis } from '../../models/interfaces/competitor-analysis.interface'
import { IDiscovery } from '../../models/interfaces/discovery.interface'
import { IGuideline } from '../../models/interfaces/guidelines.interface'
import { IMessagingEngine } from '../../models/interfaces/messaging-engine.interface'
import { IProject } from '../../models/interfaces/project.interface'
import { ICreateOrUpdateCompetitiveAnalysisPayload } from '../../models/interfaces/requests/competitive-analysis-payload.interface'
import { IMessagingEnginePayload } from '../../models/interfaces/requests/messaging-engine-payload.interface'
import { IUpdateProjectPayload } from '../../models/interfaces/requests/update-project-payload.interface'
import {
  ICreateOrUpdateCompetitiveAnalysisResponse,
  IGetCompetitiveAnalysisResponse,
  IUpdateCompetitiveAnalysisTextResponse,
} from '../../models/interfaces/responses/competitive-analysis-responses'
import {
  ICreateOrUpdateDiscoveryResponse,
  IGetDiscoveryResponse,
  IUpdateDiscoveryTextResponse,
} from '../../models/interfaces/responses/discovery-responses'
import {
  IGetMessagingEngineResponse,
  IMessagingEngineResponse,
  IUpdateMessagingEngineResponse,
} from '../../models/interfaces/responses/messaging-engine-responses'
import { IGetProjectResponse, IUpdateProjectResponse } from '../../models/interfaces/responses/projects-responses'
import {
  createOrUpdateCompetitiveAnalysisService,
  getCompetitiveAnalysisService,
  updateCompetitiveAnalysisTextTextService,
} from '../../services/competitive-analysis.service'
import {
  createOrUpdateDiscoveryService,
  getDiscoveryService,
  updateDiscoveryTextService,
} from '../../services/discovery.service'
import {
  createOrUpdateMessagingEngineService,
  getMessagingEngineService,
  updateMessagingEngineTextService,
} from '../../services/messaging-engine.service'
import { getProjectService, updateProjectService } from '../../services/projects.service'
import { ICreateOrUpdateDiscoveryPayload } from '../../models/interfaces/requests/discovery-payload.interface'

interface WizardState {
  data: {
    guideline: IGuideline | undefined
    discovery: IDiscovery | undefined
    competitiveAnalysis: ICompetitiveAnalysis | undefined
    messagingEngine: IMessagingEngine | undefined
    project?: IProject | undefined
  }
  loading: boolean
  error: string | null
}

const initialState: WizardState = {
  data: {
    guideline: undefined,
    discovery: undefined,
    competitiveAnalysis: undefined,
    messagingEngine: undefined,
    project: undefined,
  },
  loading: false,
  error: null,
}

export const getProjectThunk = createAsyncThunk<IGetProjectResponse, { projectId: string }>(
  'wizard/fetchProject',
  async ({ projectId }) => {
    return await getProjectService(projectId)
  },
)

export const updateProjectThunk = createAsyncThunk<
  IUpdateProjectResponse,
  { projectId: string; payload: IUpdateProjectPayload }
>('wizard/updateProject', async ({ projectId, payload }) => {
  return await updateProjectService(projectId, payload)
})

export const getDiscoveryThunk = createAsyncThunk<
  IGetDiscoveryResponse,
  { messagingId: string },
  { rejectValue: { status: number; message: string } }
>('wizard/fetchDiscovery', async ({ messagingId }) => {
  return await getDiscoveryService(messagingId)
})

export const updateDiscoveryTextThunk = createAsyncThunk<
  IUpdateDiscoveryTextResponse,
  { discoveryId: string; discoveryText: string },
  { rejectValue: { status: number; message: string } }
>('wizard/updateDiscoveryText', async ({ discoveryId, discoveryText }) => {
  return await updateDiscoveryTextService(discoveryId, discoveryText)
})

export const updateCompetitiveAnalysisTextThunk = createAsyncThunk<
  IUpdateCompetitiveAnalysisTextResponse,
  { competitiveAnalysisId: string; index: number; text: string },
  { rejectValue: { status: number; message: string } }
>('wizard/updateCompetitiveAnalysisText', async ({ competitiveAnalysisId, index, text }) => {
  return await updateCompetitiveAnalysisTextTextService(competitiveAnalysisId, index, text)
})

export const updateMessagingEngineTextThunk = createAsyncThunk<
  IUpdateMessagingEngineResponse,
  { messagingEngineId: string; text: string },
  { rejectValue: { status: number; message: string } }
>('wizard/updateMessagingEngineTextThunk', async ({ messagingEngineId, text }) => {
  return await updateMessagingEngineTextService(messagingEngineId, text)
})

export const getCompetitiveAnalysisThunk = createAsyncThunk<
  IGetCompetitiveAnalysisResponse,
  { messagingId: string },
  { rejectValue: { status: number; message: string } }
>('wizard/fetchCompetitiveAnalysis', async ({ messagingId }) => {
  return await getCompetitiveAnalysisService(messagingId)
})

export const getMessagingEngineThunk = createAsyncThunk<
  IGetMessagingEngineResponse,
  { messagingId: string },
  { rejectValue: { status: number; message: string } }
>('wizard/fetchMessagingEngine', async ({ messagingId }) => {
  return await getMessagingEngineService(messagingId)
})

export const createOrUpdateDiscovery = createAsyncThunk<
  ICreateOrUpdateDiscoveryResponse,
  ICreateOrUpdateDiscoveryPayload,
  { rejectValue: { status: number; message: string } }
>('wizard/saveDiscovery', async (discoveryData, { rejectWithValue }) => {
  try {
    return await createOrUpdateDiscoveryService(discoveryData)
  } catch (error: any) {
    return rejectWithValue({ status: error.response?.status || 500, message: error.message })
  }
})

export const createOrUpdateCompetitiveAnalysis = createAsyncThunk<
  ICreateOrUpdateCompetitiveAnalysisResponse,
  ICreateOrUpdateCompetitiveAnalysisPayload,
  { rejectValue: { status: number; message: string } }
>('wizard/saveCompetitiveAnalysis', async (competitiveAnalysisData, { rejectWithValue }) => {
  try {
    return await createOrUpdateCompetitiveAnalysisService(competitiveAnalysisData)
  } catch (error: any) {
    return rejectWithValue({ status: error.response?.status || 500, message: error.message })
  }
})

export const createOrUpdateMessagingEngine = createAsyncThunk<
  IMessagingEngineResponse,
  IMessagingEnginePayload,
  { rejectValue: { status: number; message: string } }
>('wizard/saveMessagingEngine', async (messagingEngineData, { rejectWithValue }) => {
  try {
    return await createOrUpdateMessagingEngineService(messagingEngineData)
  } catch (error: any) {
    return rejectWithValue({ status: error.response?.status || 500, message: error.message })
  }
})

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    updateDiscovery(state, action: PayloadAction<Partial<IDiscovery>>) {
      if (state.data?.discovery) {
        state.data.discovery = {
          ...state.data.discovery,
          ...action.payload,
        }
      } else {
        state.data.discovery = action.payload as IDiscovery
      }
    },
    updateCompetitiveAnalysis(state, action: PayloadAction<Partial<ICompetitiveAnalysis>>) {
      if (state.data?.competitiveAnalysis) {
        state.data.competitiveAnalysis = {
          ...state.data.competitiveAnalysis,
          ...action.payload,
        }
      } else {
        state.data.competitiveAnalysis = action.payload as ICompetitiveAnalysis
      }
    },
    updateMessagingEngine(state, action: PayloadAction<Partial<IMessagingEngine>>) {
      if (state.data?.messagingEngine) {
        state.data.messagingEngine = {
          ...state.data.messagingEngine,
          ...action.payload,
        }
      } else {
        state.data = {
          ...state.data,
          messagingEngine: action.payload as IMessagingEngine,
        }
      }
    },
    updateGuideline(state, action: PayloadAction<Partial<IGuideline>>) {
      if (state.data?.guideline) {
        state.data.guideline = { ...state.data.guideline, ...action.payload }
      }
    },
    updateProject(state, action: PayloadAction<Partial<IProject>>) {
      if (state.data) {
        state.data = { ...state.data, ...action.payload }
      }
    },
    resetProject(state) {
      state.data.project = undefined
      state.loading = false
      state.error = null
    },
    resetDiscovery(state) {
      state.data.discovery = undefined
      state.loading = false
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectThunk.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getProjectThunk.fulfilled, (state, action: PayloadAction<IGetProjectResponse>) => {
        state.data = {
          ...state.data,
          project: action.payload.data,
        }
        state.loading = false
      })
      .addCase(getProjectThunk.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
      .addCase(getDiscoveryThunk.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(getDiscoveryThunk.fulfilled, (state, action) => {
        state.data.discovery = action.payload.data[0]
        state.loading = false
      })
      .addCase(getDiscoveryThunk.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(getCompetitiveAnalysisThunk.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(getCompetitiveAnalysisThunk.fulfilled, (state, action) => {
        state.data.competitiveAnalysis = action.payload.data[0]
        state.loading = false
      })
      .addCase(getCompetitiveAnalysisThunk.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(getMessagingEngineThunk.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(getMessagingEngineThunk.fulfilled, (state, action) => {
        state.data.messagingEngine = action.payload.data[0]
        state.loading = false
      })
      .addCase(getMessagingEngineThunk.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(updateProjectThunk.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateProjectThunk.fulfilled, (state, action) => {
        const { name, status, toneOfVoice, users } = action.payload.data
        if (state.data.project) {
          state.data.project.name = name
          state.data.project.status = status
          state.data.project.toneOfVoice = toneOfVoice
          state.data.project.users = users
        }
        state.loading = false
      })
      .addCase(updateProjectThunk.rejected, (state) => {
        state.loading = false
      })
  },
})
export const {
  resetProject,
  updateMessagingEngine,
  updateCompetitiveAnalysis,
  updateDiscovery,
  updateProject,
  resetDiscovery,
} = wizardSlice.actions
export default wizardSlice.reducer
