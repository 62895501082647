import { IDiscovery } from '../../../models/interfaces/discovery.interface'
import { IGuideline } from '../../../models/interfaces/guidelines.interface'
import { EWizardType, IWizardType } from '../../../models/interfaces/wizard-type.interface'

const createLanguageGuidelines = (guidelines?: IGuideline): IWizardType[] => {
  if (!guidelines) return []

  const result: IWizardType[] = []

  if (guidelines.acronyms) {
    result.push({ type: EWizardType.TEXT, value: guidelines.acronyms })
  }
  if (guidelines.grammar) {
    result.push({ type: EWizardType.TEXT, value: guidelines.grammar })
  }
  if (guidelines.grammaticalStyles) {
    result.push({ type: EWizardType.TEXT, value: guidelines.grammaticalStyles })
  }
  if (guidelines.terminology) {
    result.push({ type: EWizardType.TEXT, value: guidelines.terminology })
  }
  if (guidelines.toneOfVoice) {
    result.push({ type: EWizardType.TEXT, value: guidelines.toneOfVoice })
  }

  return result
}

const removeNameField = (items: IWizardType[]): Omit<IWizardType, 'name'>[] => {
  return items.map(({ name, ...rest }) => rest)
}

export { createLanguageGuidelines, removeNameField }
