import { apiEndpoints } from '../constants/api-endpoints'
import { IMessagingEnginePayload } from '../models/interfaces/requests/messaging-engine-payload.interface'
import {
  IGetMessagingEngineResponse,
  IMessagingEngineResponse,
  IUpdateMessagingEngineResponse,
} from '../models/interfaces/responses/messaging-engine-responses'
import { apiRequest } from '../utils/api-utils/api'

const getMessagingEngineService = async (messagingId: string): Promise<IGetMessagingEngineResponse> => {
  return await apiRequest('GET', apiEndpoints.MESSAGING_ENGINE.GET_MESSAGING_ENGINE(messagingId))
}
const createOrUpdateMessagingEngineService = async (
  messagingEngineData: IMessagingEnginePayload,
): Promise<IMessagingEngineResponse> => {
  try {
    return await apiRequest(
      'POST',
      apiEndpoints.MESSAGING_ENGINE.CREATE_OR_UPDATE_MESSAGING_ENGINE,
      messagingEngineData,
    )
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error creating or updating messaging engine')
  }
}
const updateMessagingEngineTextService = async (
  messagingEngineId: string,
  text: string,
): Promise<IUpdateMessagingEngineResponse> => {
  return await apiRequest('PATCH', apiEndpoints.MESSAGING_ENGINE.UPDATE_MESSAGING_ENGINE_TEXT, {
    messagingEngineId,
    text,
  })
}

export { createOrUpdateMessagingEngineService, getMessagingEngineService, updateMessagingEngineTextService }
