import { ConfigProvider, Steps } from 'antd'
import { FC } from 'react'

import styles from './WizardStepper.module.scss'

interface WizardStepperProps {
  currentStep: number
  onStepChange: (step: number) => void
}

const WizardStepper: FC<WizardStepperProps> = ({ currentStep, onStepChange }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#807fdc',
          fontFamily: "'OutfitRegular', sans-serif",
          colorText: '#8e8df4',
        },
      }}
    >
      <div className={styles.wizardStepperContainer}>
        <Steps
          className={styles.stepper}
          current={currentStep}
          onChange={onStepChange}
          items={[{ title: 'Discovery' }, { title: 'Competitive Analysis' }, { title: 'Messaging Engine' }]}
        />
      </div>
    </ConfigProvider>
  )
}

export { WizardStepper }
