import { Collapse, message } from 'antd'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useRef } from 'react'

import { CompetitorIcon, CompletedGreenIcon } from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import { ICompetitive } from '../../../models/interfaces/competitor-analysis.interface'
import {
  createOrUpdateCompetitiveAnalysis,
  getCompetitiveAnalysisThunk,
  updateCompetitiveAnalysis,
} from '../../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import WizardStepGenericComponent from '../WizardStepGenericComponent/WizardStepGenericComponent'
import styles from './WizardCompetitiveAnalysis.module.scss'
import WizardCompetitor from './WizardCompetitor/WizardCompetitor'
import { isDateAfter } from '../../../utils/date.util'

interface CompetitorAnalysisItem {
  key: number
  label: ReactNode
  children: ReactNode
  icon?: ReactNode
  badge?: ReactNode
  data?: ICompetitive
}

interface IWizardCompetitiveAnalysisProps {
  messagingId?: string
  AILoading: (isLoading: boolean) => void
  onLoading: (isLoading: boolean) => void
  emitCompaniesNames: (companiesNames: string[]) => void
}

const WizardCompetitiveAnalysis: FC<IWizardCompetitiveAnalysisProps> = ({
  messagingId,
  AILoading,
  onLoading,
  emitCompaniesNames,
}) => {
  const dispatch = useAppDispatch()
  const competitiveAnalysis = useAppSelector((state) => state.wizard.data?.competitiveAnalysis)
  const pollingInterval = useRef<NodeJS.Timeout | null>(null)

  const [activeKey, setActiveKey] = useState<string>('')
  const [completedKeys, setCompletedKeys] = useState<number[]>([])
  const [competitorItems, setCompetitorItems] = useState<CompetitorAnalysisItem[]>([])

  useEffect(() => {
    if (!messagingId) return
    onLoading(true)
    ;(async () => {
      try {
        await dispatch(getCompetitiveAnalysisThunk({ messagingId }))
      } catch (error) {
        message.error('Error fetching competitive analysis')
        console.error('Error: ', error)
      } finally {
        onLoading(false)
      }
    })()
  }, [dispatch, messagingId, onLoading])

  useEffect(() => {
    const competitiveArray = competitiveAnalysis?.competitors
    if (competitiveArray) {
      const fetchedCompetitorItems: CompetitorAnalysisItem[] = competitiveArray.map((item, index) => ({
        key: index,
        label: <span className={styles.competitorItem}>{item.companyName}</span>,
        icon: <CompetitorIcon style={{ marginRight: 8 }} />,
        data: item,
        children: (
          <WizardCompetitor
            id={index}
            value={item}
            onRemove={() => handleRemove(index)}
            onNext={(formData) => handleNext(formData, index)}
          />
        ),
      }))
      setCompetitorItems(fetchedCompetitorItems)
      const competitorKeys = competitiveArray.map((_, index) => index)
      setCompletedKeys(competitorKeys)
    }
  }, [dispatch, messagingId, competitiveAnalysis])

  useEffect(() => {
    if (competitiveAnalysis)
      emitCompaniesNames(competitiveAnalysis.competitors.map((competitor) => competitor.companyName))
  }, [competitiveAnalysis, competitiveAnalysis?.competitors, emitCompaniesNames])

  useEffect(() => {
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current)
      }
    }
  }, [])

  const generateCompetitiveAnalysis = async () => {
    if (messagingId && competitiveAnalysis) {
      const { metadata, createdAt, updatedAt, competitorsText, ...filteredData } = competitiveAnalysis
      const companiesNames = filteredData.competitors.map((competitor) => competitor.companyName)

      emitCompaniesNames(companiesNames)
      AILoading(true)

      try {
        const result = await dispatch(createOrUpdateCompetitiveAnalysis(filteredData))
        if (result.meta.requestStatus === 'rejected' || createOrUpdateCompetitiveAnalysis.rejected.match(result)) {
          throw new Error('Error while generating AI')
        }
        pollForUpdatedCompetitiveAnalysis(result?.payload?.data?.updatedAt)
      } catch (error) {
        message.error('Something went wrong while generating competitive analysis')
        console.error('Error: ', error)
        AILoading(false)
      }
    }
  }

  const pollForUpdatedCompetitiveAnalysis = (updatedAt: string | undefined) => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current)
    }

    pollingInterval.current = setInterval(async () => {
      try {
        if (messagingId) {
          const resultAction = await dispatch(getCompetitiveAnalysisThunk({ messagingId }))

          if (getCompetitiveAnalysisThunk.rejected.match(resultAction)) {
            throw new Error(resultAction.error.message || 'Error fetching discovery')
          }

          const updatedCompetitive = resultAction.payload.data[0]
          if (!updatedCompetitive) return

          const isCompetitiveAnalysisUpdated = isDateAfter(updatedCompetitive.updatedAt || '', updatedAt || '')

          if (
            'competitorsText' in updatedCompetitive &&
            Array.isArray(updatedCompetitive.competitorsText) &&
            updatedCompetitive.competitorsText.every((element) => element !== null && element !== undefined) &&
            isCompetitiveAnalysisUpdated
          ) {
            AILoading(false)
            clearInterval(pollingInterval.current!)
          }
        }
      } catch (error) {
        console.error('Polling error:', error)
        AILoading(false)
        clearInterval(pollingInterval.current!)
      }
    }, 5000)
  }

  const handleNext = (newCompetitorData: ICompetitive, index: number) => {
    setCompetitorItems((prevItems) =>
      prevItems.map((item, idx) =>
        idx === index
          ? {
              ...item,
              label: <span className={styles.competitorItem}>{newCompetitorData.companyName}</span>,
              data: newCompetitorData,
              children: (
                <WizardCompetitor
                  id={idx}
                  value={newCompetitorData}
                  onRemove={() => handleRemove(idx)}
                  onNext={(formData) => handleNext(formData, idx)}
                />
              ),
            }
          : item,
      ),
    )

    const currentCompetitors = competitiveAnalysis?.competitors || []

    let updatedCompetitors: ICompetitive[]
    if (currentCompetitors[index]) {
      updatedCompetitors = currentCompetitors.map((competitor, idx) => (idx === index ? newCompetitorData : competitor))
    } else {
      updatedCompetitors = [...currentCompetitors, newCompetitorData]
    }

    dispatch(updateCompetitiveAnalysis({ competitors: updatedCompetitors }))
    setActiveKey('')
    setCompletedKeys((prev) => {
      const newKeys = [...prev, index]
      return [...new Set(newKeys)]
    })
  }

  const handleChange = (key: string | string[]) => {
    setActiveKey(key as string)
  }

  const handleRemove = async (indexToRemove: number) => {
    setCompetitorItems((prevItems) => prevItems.filter((_, idx) => idx !== indexToRemove))
    setCompletedKeys((prevKeys) => prevKeys.filter((key) => key !== indexToRemove))
    setActiveKey('')

    if (competitiveAnalysis) {
      const remainingCompetitors = competitiveAnalysis.competitors.filter((_, idx) => idx !== indexToRemove)

      if (!competitiveAnalysis.competitorsText) return
      const updatedCompetitorsText = [
        ...competitiveAnalysis.competitorsText.slice(0, indexToRemove),
        ...competitiveAnalysis.competitorsText.slice(indexToRemove + 1),
      ]

      dispatch(
        updateCompetitiveAnalysis({
          competitors: remainingCompetitors,
          competitorsText: updatedCompetitorsText,
        }),
      )
    }
  }

  const addCompetitor = () => {
    const defaultIndex = competitorItems.length

    if (competitorItems.find((item) => item.key === defaultIndex)) {
      message.error(strings.WIZARD.COMPETITIVE_ANALYSIS.ERROR)
      return
    }

    const newCompetitor: CompetitorAnalysisItem = {
      key: defaultIndex,
      label: <span className={styles.competitorItem}>{`${strings.WIZARD.COMPETITIVE_ANALYSIS.ITEM_TITLE}`}</span>,
      icon: <CompetitorIcon style={{ marginRight: 8 }} />,
      children: (
        <WizardCompetitor
          id={defaultIndex}
          onRemove={() => handleRemove(defaultIndex)}
          onNext={(formData) => handleNext(formData, defaultIndex)}
        />
      ),
    }
    setCompetitorItems((prevItems) => [...prevItems, newCompetitor])
    setActiveKey('')
  }

  const availableGenerate = completedKeys.length > 0
  return (
    <WizardStepGenericComponent
      title={strings.WIZARD.COMPETITIVE_ANALYSIS.TITLE}
      subTitle={strings.WIZARD.COMPETITIVE_ANALYSIS.SUB_TITLE}
      availableGenerate={availableGenerate}
      generate={generateCompetitiveAnalysis}
      onlyGenerateButton
    >
      {competitorItems.map((item) => (
        <Collapse
          key={item.key}
          className={styles.panel}
          activeKey={activeKey}
          expandIconPosition="end"
          accordion
          onChange={(key) => handleChange(key)}
          style={{ alignItems: 'center' }}
        >
          <Collapse.Panel
            key={item.key}
            header={
              <div className={styles.dropDownHeader}>
                {item.icon}
                {item.label}
                {completedKeys.includes(item.key) && (
                  <div className={styles.completeBadge}>
                    <CompletedGreenIcon />
                    <p className={styles.badgeTitle}>{strings.GENERAL_LABELS.COMPLETED}</p>
                  </div>
                )}
              </div>
            }
          >
            {item.children}
          </Collapse.Panel>
        </Collapse>
      ))}
      <button className={styles.addCompetitor} onClick={addCompetitor}>
        {`+ ${competitorItems.length === 0 ? strings.WIZARD.COMPETITIVE_ANALYSIS.ADD_ITEM : strings.WIZARD.COMPETITIVE_ANALYSIS.ADD_ANOTHER_ITEM}`}
      </button>
    </WizardStepGenericComponent>
  )
}

export default WizardCompetitiveAnalysis
