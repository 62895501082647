import { apiEndpoints } from '../constants/api-endpoints'
import { EFileReferenceType } from '../models/enums/file-reference-type.enum'
import { IFile } from '../models/interfaces/file.interface'
import {
  IGetFilesResponse,
  ISaveFileToDBResponse,
  IUploadFileResponse,
} from '../models/interfaces/responses/files.responses'
import { apiRequest } from '../utils/api-utils/api'

const uploadFileService = async (formData: FormData): Promise<IUploadFileResponse> => {
  return await apiRequest('POST', apiEndpoints.UPLOAD.UPLOAD_TO_STORAGE, formData)
}

const SaveFileToDBService = async (file: IFile): Promise<ISaveFileToDBResponse> => {
  return await apiRequest('POST', apiEndpoints.FILES.CREATE_FILE, file)
}

const getFilesService = async (referenceType: EFileReferenceType, referenceId: string): Promise<IGetFilesResponse> => {
  return await apiRequest('GET', apiEndpoints.FILES.GET_FILES(referenceType, referenceId))
}

const deleteFileService = async (id: string): Promise<void> => {
  return await apiRequest('DELETE', apiEndpoints.FILES.DELETE_FILE, { id })
}

export { SaveFileToDBService, deleteFileService, getFilesService, uploadFileService }
