import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchUserData } from '../../services/users.service'
import { IUserMeResponse } from '../../models/interfaces/responses/users-responses'

interface UserState {
  id: string
  email: string
  firstName: string
  lastName: string
  lastLogin?: string
  createdAt?: string
  updatedAt?: string
}

const initialState: UserState = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  lastLogin: '',
  createdAt: '',
  updatedAt: '',
}

export const fetchConnectedUser = createAsyncThunk<UserState>('user/fetchConnectedUser', async () => {
  const userData: IUserMeResponse = await fetchUserData()
  return userData.data
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserState>) => {
      state.id = action.payload.id
      state.email = action.payload.email
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.lastLogin = action.payload.lastLogin
      state.createdAt = action.payload.createdAt
      state.updatedAt = action.payload.updatedAt
    },
    logout: (state) => {
      Object.assign(state, initialState)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConnectedUser.fulfilled, (state, action: PayloadAction<UserState>) => {
        state.id = action.payload.id
        state.email = action.payload.email
        state.firstName = action.payload.firstName
        state.lastName = action.payload.lastName
        state.lastLogin = action.payload.lastLogin
        state.createdAt = action.payload.createdAt
        state.updatedAt = action.payload.updatedAt
      })
      .addCase(fetchConnectedUser.rejected, (state, action) => {
        console.error('Failed to fetch connected user:', action.error.message)
      })
  },
})

export const { login, logout } = userSlice.actions
export default userSlice.reducer
