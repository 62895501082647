import { FunctionComponent } from 'react'

import { fetchNewsThunk } from '../../../store/slices/news.slice'
import { useAppDispatch } from '../../../store/store'
import { Loader } from '../../Loader/Loader'
import { OfflineMsg } from '../../OfflineMsg/OfflineMsg'
import { RefreshButton } from '../../RefreshButton/RefreshButton'
import { NewsListItem } from '../NewsListItem/NewsListItem'
import styles from './NewsScroller.module.scss'
import { INews } from '../../../models/interfaces/news.interface'
import { EmptyMsg } from '../../EmptyMsg/EmptyMsg'

interface INewsScrollerProps {
  title: string
  news: INews[]
  isLoading: boolean
  error?: string | null
}

const NewsScroller: FunctionComponent<INewsScrollerProps> = ({ title, news, isLoading, error }) => {
  const dispatch = useAppDispatch()
  let content: JSX.Element

  if (isLoading) {
    content = <Loader />
  } else if (error) {
    content = <OfflineMsg />
  } else if (!news?.length) {
    content = <EmptyMsg />
  } else {
    content = <NewsListItem news={news} />
  }

  const handleRefresh = () => {
    dispatch(fetchNewsThunk())
  }
  return (
    <div className={styles.newsScroller}>
      <div className={styles.newsTopContainer}>
        <h2 className={styles.newsScrollerTitle}>{title}</h2>
        <RefreshButton onClick={handleRefresh} />
      </div>
      <div className={styles.newsBox}>{content}</div>
    </div>
  )
}

export { NewsScroller }
