import { apiEndpoints } from '../constants/api-endpoints'
import { IGetUsersResponse, IUserMeResponse } from '../models/interfaces/responses/users-responses'
import { apiRequest } from '../utils/api-utils/api'

const fetchAllUsers = async (): Promise<IGetUsersResponse> => {
  return await apiRequest('GET', apiEndpoints.USERS.GET_ALL_USERS)
}

const fetchUserData = async (): Promise<IUserMeResponse> => {
  return await apiRequest('GET', apiEndpoints.USERS.GET_USER_DATA)
}

export { fetchAllUsers, fetchUserData }
