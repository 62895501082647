export enum EWizardType {
  TEXT = 'text',
  URL = 'url',
  FILE = 'file',
}

export interface IWizardType {
  type: EWizardType
  value: string
  name?: string
  id?: string
}

export interface IDataItem {
  text: string
  type: string
  value: string
}
