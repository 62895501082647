import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { INews } from '../../models/interfaces/news.interface'
import { IGetNewsResponse } from '../../models/interfaces/responses/news-responses'
import { fetchNewsService } from '../../services/news.service'

interface INewsState {
  news: INews[]
  isNewsLoading: boolean
  newsError: string | null
}

const initialState: INewsState = {
  news: [],
  isNewsLoading: true,
  newsError: null,
}

export const fetchNewsThunk = createAsyncThunk<IGetNewsResponse>('news/fetchNews', async () => {
  const response = await fetchNewsService()
  return response
})

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    clearNews: (state) => {
      state.news = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsThunk.pending, (state) => {
        state.isNewsLoading = true
        state.newsError = null
      })
      .addCase(fetchNewsThunk.fulfilled, (state, action: PayloadAction<IGetNewsResponse>) => {
        state.news = action.payload.data
        state.isNewsLoading = false
      })
      .addCase(fetchNewsThunk.rejected, (state, action) => {
        state.isNewsLoading = false
        state.newsError = action.error.message || 'Failed to fetch news'
      })
  },
})

export const { clearNews } = newsSlice.actions

export default newsSlice.reducer
