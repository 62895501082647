import { apiEndpoints } from '../constants/api-endpoints'
import { INews } from '../models/interfaces/news.interface'
import { IGetNewsResponse } from '../models/interfaces/responses/news-responses'
import { apiRequest } from '../utils/api-utils/api'

const fetchNewsService = async (): Promise<IGetNewsResponse> => {
  const response = await apiRequest('GET', apiEndpoints.NEWS.GET_NEWS)
  const news = response.data.map((news: INews) => {
    const [name] = news.relatedKeywords || []
    return {
      name,
      title: news.title,
      description: news.description,
      content: news.content,
      publishedAt: news.publishedAt,
      source: news.source,
      url: news.url,
    }
  })
  return {
    statusCode: response.status,
    message: response.statusText,
    data: news,
  }
}

export { fetchNewsService }
