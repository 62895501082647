import { apiEndpoints } from '../constants/api-endpoints'
import { ICreateOrUpdateCompetitiveAnalysisPayload } from '../models/interfaces/requests/competitive-analysis-payload.interface'
import {
  ICreateOrUpdateCompetitiveAnalysisResponse,
  IGetCompetitiveAnalysisResponse,
  IUpdateCompetitiveAnalysisTextResponse,
} from '../models/interfaces/responses/competitive-analysis-responses'
import { apiRequest } from '../utils/api-utils/api'

const getCompetitiveAnalysisService = async (messagingId: string): Promise<IGetCompetitiveAnalysisResponse> => {
  return await apiRequest('GET', apiEndpoints.COMPETITIVE_ANALYSIS.GET_COMPETITIVE_ANALYSIS(messagingId))
}
const createOrUpdateCompetitiveAnalysisService = async (
  competitiveAnalysisData: ICreateOrUpdateCompetitiveAnalysisPayload,
): Promise<ICreateOrUpdateCompetitiveAnalysisResponse> => {
  try {
    return await apiRequest(
      'POST',
      apiEndpoints.COMPETITIVE_ANALYSIS.CREATE_OR_UPDATE_COMPETITIVE_ANALYSIS,
      competitiveAnalysisData,
    )
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error creating or updating competitive analysis')
  }
}
const updateCompetitiveAnalysisTextTextService = async (
  competitiveAnalysisId: string,
  index: number,
  text: string,
): Promise<IUpdateCompetitiveAnalysisTextResponse> => {
  return await apiRequest('PATCH', apiEndpoints.COMPETITIVE_ANALYSIS.UPDATE_COMPETITIVE_ANALYSIS_TEXT, {
    competitiveAnalysisId,
    index,
    text,
  })
}

export {
  createOrUpdateCompetitiveAnalysisService,
  getCompetitiveAnalysisService,
  updateCompetitiveAnalysisTextTextService,
}
