import classNames from 'classnames'
import { CSSProperties, FC, memo } from 'react'

import { EStatusEnum } from '../../models/enums/status.enum'
import styles from './StatusBox.module.scss'
import { getStatusClass, getStatusDisplayName } from '../../utils/status.utils'

interface StatusBoxProps {
  status?: EStatusEnum
  className?: string
  style?: CSSProperties
}

const StatusBox: FC<StatusBoxProps> = memo(({ status, className, style }) => {
  const statusClass = getStatusClass(status)
  const displayName = status ? getStatusDisplayName(status) : ''

  return (
    <div style={style} className={classNames(styles.statusBox, styles[statusClass], className)}>
      {displayName}
    </div>
  )
})

export { StatusBox }
